import { postImage } from "@app/imageBank/imageBank.effect.js";
import { notify } from "@app/notification/notification.action.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";
import * as effect from "./papercode.effect.js";
import { editProductText } from "@app/productTexts/productTexts.action.js";
import { addProductTexts } from "@utils/productTexts.js";

const SLICE = "products";

export const ADMIN_PRODUCT_IS_LOADING_PAPER_CODES = `${SLICE}/IS_LOADING_PAPER_CODES`;
export const ADMIN_PRODUCT_SET_ALL_PAPER_CODES = `${SLICE}/SET_ALL_PAPER_CODES`;
export const requestAllPaperCodes = () => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRODUCT_IS_LOADING_PAPER_CODES));

  const response = await effect.fetchAllPaperCodes();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_ALL_PAPER_CODES, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_ALL_PAPER_CODES, response));
};

export const addPaperCode = (paperCode, callback = () => { }) => async (dispatch) => {

  let descriptivetextid = await addProductTexts(paperCode);
  if (descriptivetextid) {
    paperCode.descriptivetextid = descriptivetextid;
    paperCode.textid = descriptivetextid;
  }

  if (paperCode.imageid) {
    let imgUploadRes = await postImage(paperCode.imageid);
    imgUploadRes = await imgUploadRes.json();
    paperCode.imageid = imgUploadRes.imageid;
  }

  if (paperCode.papergroupid) {
    await effect.postPaperCodeInGroup(paperCode);
  }

  const response = await effect.postPaperCode(paperCode);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("paperCodeAdded"), "success"));
  dispatch(requestAllPaperCodes());
};

export const ADMIN_PRODUCT_SET_REMOVING_PAPER_CODE = `${SLICE}/SET_REMOVING_PAPER_CODE`;
export const ADMIN_PRODUCT_PAPER_CODE_REMOVED = `${SLICE}/PARAMS_GROUP_REMOVED`;
export const removePaperCode = (paperCode, callback = () => { }) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_REMOVING_PAPER_CODE, true));

  const response = await effect.deletePaperCode(paperCode);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_REMOVING_PAPER_CODE, false));
    return;
  }
  callback();
  dispatch(notify(tr("paperCodeRemoved"), "warning"));
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_REMOVING_PAPER_CODE, false));
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_PAPER_CODE_REMOVED, paperCode));
};

export const ADMIN_PRODUCT_PAPER_CODE_EDITED = `${SLICE}/PAPER_CODE_EDITED`;
export const editPaperCode = (paperCode, callback = () => { }) => async (dispatch) => {

  // Image edit stopped for now.
  // let imgUploadRes;
  // let isDefaultImage = paperCode.imageid.id === 0;
  // if (isDefaultImage) {
  //   imgUploadRes = await postImage(paperCode.imageid);
  // }
  // else {
  //   imgUploadRes = await putImage(paperCode.imageid);
  // }
  // imgUploadRes = await imgUploadRes.json();
  // paperCode.imageid = isDefaultImage ? imgUploadRes.imageid : paperCode.imageid.id;

  if (!paperCode.descriptivetextid || paperCode.descriptivetextid == 0) {
    let descriptivetextid = await addProductTexts(paperCode);
    if (descriptivetextid) {
      paperCode.descriptivetextid = descriptivetextid;
      paperCode.textid = descriptivetextid;
    }
  }
  else {
    dispatch(editProductText(paperCode));
  }

  if (!paperCode.textid || paperCode.textid == 0) {
    paperCode.textid = paperCode.descriptivetextid;
  }

  if (paperCode.papergroupid !== paperCode.oldPaperGroupId) {
    await effect.deletePaperCodeFromGroup(paperCode);
    await effect.postPaperCodeInGroup(paperCode);
  }

  const response = await effect.putPaperCode(paperCode);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("paperCodeEdited"), "success"));
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_PAPER_CODE_EDITED, paperCode));
  dispatch(requestAllPaperCodes());
};

export const ADMIN_PRODUCT_IS_LOADING_PAPER_CODE_GROUPS = `${SLICE}/IS_LOADING_PAPER_CODE_GROUPS`;
export const ADMIN_PRODUCT_SET_ALL_PAPER_CODE_GROUPS = `${SLICE}/SET_ALL_PAPER_CODE_GROUPS`;
export const requestPaperCodeGroups = () => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRODUCT_IS_LOADING_PAPER_CODE_GROUPS));

  const response = await effect.fetchPaperCodeGroups();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_ALL_PAPER_CODE_GROUPS, []));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_ALL_PAPER_CODE_GROUPS, response));
};


