/*
 * Functions for interacting with browser's localStorage
 */

// Function to check whether localStorage is supported and available.
// Implementation taken from MDN web docs URL:
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
const _storageAvailable = () => {
  let storage;
  try {
    storage = window.localStorage;
    const test = "__test";

    storage.setItem(test, test);
    storage.removeItem(test);

    return true;
  } catch (err) {
    return (
      err instanceof DOMException &&
      // Everything except Firefox
      (err.code === 22 ||
        // Firefox
        err.code === 1014 ||
        // Code might not be present
        // Everything except Firefox
        err.name === "QuotaExceededError" ||
        // Firefox
        err.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // Acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0
    );
  }
};

const storageAvailable = _storageAvailable();

// General setters and getters

// Disallow using storage methods if storage is not available
const _op = (method, ...items) => {
  return storageAvailable ? localStorage[method](...items) : null;
};

const _get = (key) => {
  return _op("getItem", key);
};

const _getJson = (key) => {
  const val = _get(key);
  return val !== null ? JSON.parse(val) : null;
};

const _set = (key, value) => {
  _op("setItem", key, value);
};

const _setJson = (key, value) => {
  _op("setItem", key, JSON.stringify(value));
};

const remove = (...keys) => {
  for (const key of keys) {
    _op("removeItem", key);
  }
};

// Translation related functions
// Currently only one translation can be in the localStorage at once

const translation = () => {
  const langCode = _get("langCode");
  const translations = _getJson("translations");

  if (langCode === null || translations === null) {
    return null;
  }

  return {
    langCode: langCode,
    translations: translations,
  };
};

const writeTranslation = (langCode, translations) => {
  _set("langCode", langCode);
  _setJson("translations", translations);
};

const getTranslationDebugMode = () => {
  const translationDebugMode = _get("translationDebugMode");

  if (translationDebugMode == null) {
    return null;
  }

  return translationDebugMode;
};

const writeTranslationDebugMode = (debugMode) => {
  _set("translationDebugMode", debugMode);
};

// User data related functions

const writeUserInfo = (key, values) => {
  let data = _getJson("user");
  if (data === null) {
    data = {};
  }

  data[key] = values;

  _setJson("user", data);
};

const userInfo = (key) => {
  const data = _getJson("user");
  if (data === null || !(key in data)) {
    return null;
  }

  return data[key];
};

const setCustomerInformationOpen = (cusInfoOpen) => {
  _set("customerInformationOpen", cusInfoOpen);
};

const getCustomerInformationOpen = () => {
  return _get("customerInformationOpen");
};

const setCustomerPageActiveTab = (activeTabNum) => {
  _set("customerPageActiveTab", activeTabNum);
};

const getCustomerPageActiveTab = () => {
  return _get("customerPageActiveTab");
};

export {
  translation,
  writeTranslation,
  getTranslationDebugMode,
  writeTranslationDebugMode,
  writeUserInfo,
  userInfo,
  remove,
  setCustomerInformationOpen,
  getCustomerInformationOpen,
  setCustomerPageActiveTab,
  getCustomerPageActiveTab,
};
