import { notify } from "@app/notification/notification.action.js";
import * as effect from "./parameters.effect";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";

const SLICE = "adminParameters";


export const ADMIN_PARAMS_IS_LOADING_PARAMETERS_GROUP = `${SLICE}/IS_LOADING_PARAMETERS_GROUP`;
export const ADMIN_PARAMS_SET_ALL_PARAMETERS_GROUP = `${SLICE}/SET_ALL_PARAMETERS_GROUP`;
export const requestAllParametersGroup = () => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_PARAMS_IS_LOADING_PARAMETERS_GROUP));

  const response = await effect.fetchAllParametersGroup();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PARAMS_SET_ALL_PARAMETERS_GROUP, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  dispatch(actionCreator.createAction(ADMIN_PARAMS_SET_ALL_PARAMETERS_GROUP, response));
};

export const ADMIN_PARAMS_IS_LOADING_PARAMETERS = `${SLICE}/IS_LOADING_PARAMETERS`;
export const ADMIN_PARAMS_SET_ALL_PARAMETERS = `${SLICE}/SET_ALL_PARAMETERS`;
export const requestAllParameters = (row) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_PARAMS_IS_LOADING_PARAMETERS));

  const response = await effect.fetchAllParameters(row);
  console.log({response})
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PARAMS_SET_ALL_PARAMETERS, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  dispatch(actionCreator.createAction(ADMIN_PARAMS_SET_ALL_PARAMETERS, response));
};

export const ADMIN_PARAMS_IS_LOADING_PARAMETER = `${SLICE}/IS_LOADING_PARAMETER`;
export const ADMIN_PARAMS_SET_PARAMETER = `${SLICE}/SET_ALL_PARAMETER`;
export const requestParameter = (row) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_PARAMS_IS_LOADING_PARAMETER));

  const response = await effect.fetchParameter(row);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PARAMS_SET_PARAMETER, {}));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  dispatch(actionCreator.createAction(ADMIN_PARAMS_SET_PARAMETER, response));
};

export const ADMIN_PARAMS_SET_REMOVING_PARAMS_GROUP = `${SLICE}/SET_REMOVING_PARAMS_GROUP`;
export const ADMIN_PARAMS_PARAMS_GROUP_REMOVED = `${SLICE}/PARAMS_GROUP_REMOVED`;
export const removeParamsGroup = (paramsGroup, callback = () => { }) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_PARAMS_SET_REMOVING_PARAMS_GROUP, true));

  const response = await effect.deleteParamsGroup(paramsGroup);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_PARAMS_SET_REMOVING_PARAMS_GROUP, false));
    return;
  }

  callback();
  dispatch(notify(tr("paramsGroupRemoved"), "warning"));
  dispatch(actionCreator.createAction(ADMIN_PARAMS_SET_REMOVING_PARAMS_GROUP, false));
  dispatch(actionCreator.createAction(ADMIN_PARAMS_PARAMS_GROUP_REMOVED, paramsGroup));
};



export const ADMIN_PARAMS_SET_REMOVING_PARAMETER = `${SLICE}/SET_REMOVING_PARAMETER`;
export const ADMIN_PARAMS_PARAMETER_REMOVED = `${SLICE}/PARAMETER_REMOVED`;
export const removeParameter = (parameter, callback = () => { }) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_PARAMS_SET_REMOVING_PARAMETER, true));
  const response = await effect.deleteParameter(parameter);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_PARAMS_SET_REMOVING_PARAMETER, false));
    return;
  }

  callback();
  dispatch(notify(tr("parameterRemoved"), "warning"));
  dispatch(actionCreator.createAction(ADMIN_PARAMS_SET_REMOVING_PARAMETER, false));
  dispatch(actionCreator.createAction(ADMIN_PARAMS_PARAMETER_REMOVED, parameter));
};


export const addParamsGroup = (paramsGroup, callback = () => { }) => async (dispatch) => {

  const response = await effect.postParamsGroup(paramsGroup);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("paramsGroupAdded"), "success"));
  dispatch(requestAllParametersGroup());
};

export const addParameters = (parameters, callback = () => { }) => async (dispatch) => {

  const response = await effect.postParameters(parameters);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("parametersAdded"), "success"));
  dispatch(requestAllParameters({ codegroupno: parameters.codegroupno, papercode: parameters.papercode }));
};

export const ADMIN_PARAMS_PARAMS_GROUP_EDITED = `${SLICE}/PARAMS_GROUP_EDITED`;
export const editParamsGroup = (paramsGroup, callback = () => { }) => async (dispatch) => {

  const response = await effect.putParamsGroup(paramsGroup);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("paramsGroupEdited"), "success"));
  dispatch(actionCreator.createAction(ADMIN_PARAMS_PARAMS_GROUP_EDITED, paramsGroup));
};


export const editParameters = (parameters, callback = () => { }) => async (dispatch) => {

  if (parameters.oldCodeNo !== parameters.codeno) {
    const response = await effect.putParameter(parameters, true);
    if (response.error) {
      dispatch(notify(tr("dbConnectFailure"), "error"));
      return;
    }
  }

    const response = await effect.putParameter(parameters, false);
    if (response.error) {
      dispatch(notify(tr("dbConnectFailure"), "error"));
      return;
    }

  callback();
  dispatch(notify(tr("parameterEdited"), "success"));
  dispatch(requestAllParameters({ codegroupno: parameters.codegroupno, papercode: parameters.papercode }));
};











