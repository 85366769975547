import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

/**
 * SubmitButton can be used in redux-forms as a submit button.
 * It needs redux-form's submitting status and, during submitting, displays
 * loading indicator instead of a text inside the button.
 *
 * Usage:
 * ```
 * <form onSubmit={props.handleSubmit}>
 *   ...
 *   <SubmitButton submitting={props.submitting} text='Login' />
 * </form>
 * ```
 */
const SubmitButton = (props) => {
  const { submitting, text, color = "primary", classes = "", ...rest } = props;

  if (submitting) {
    return (
      <Button color={color} className={`submit-button active${classes}`}>
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </Button>
    );
  }

  return (
    <Button {...rest} color={color} className={`submit-button${classes}`} type="submit">
      {text}
    </Button>
  );
};

SubmitButton.propTypes = {
  submitting: PropTypes.bool.isRequired, // Submitting status from redux-form
  text: PropTypes.string.isRequired, // Text for the button
  classes: PropTypes.string, // Optional styles passed to button
  color: PropTypes.string,
};

/**
 * Create a link to the given customer.
 * LinkToCustomer is just a simple wrapper around react-router Link component which
 * purpose is to mask URL handling and react-router usage from other components.
 */
const LinkToCustomer = ({ cusno, text }) => {
  return (
    <Link to={`/customer/${cusno}`} className="font-weight-bold">
      {text}
    </Link>
  );
};

LinkToCustomer.propTypes = {
  cusno: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string.isRequired,
};

export { SubmitButton, LinkToCustomer };
