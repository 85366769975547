import React from "react";
import PropTypes from "prop-types";
import {
  Dropdown as ReactstrapDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

/**
 * Generic Dropdown component. Renders list of dropdown items from the array that
 * is given as a prop.
 *
 * Usage:
 * ```
 * <Dropdown
 *   isOpen={this.state.dropdownOpen}
 *   toggle={this.toggleDropdown}
 *   sendSelection={this.handleDropdownSelection}
 *   items={['1', '2', '3']}
 *   placeholder='Select number'
 * />
 * ```
 */
class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    // save indexes for items and return it on select
    const indexes = {};
    props.items.forEach((item, index) => {
      indexes[item] = index;
    });

    this.state = {
      itemSelected: props.placeholder,
      open: props.open || false,
      indexes: indexes,
    };
  }

  _handleItemSelection(item) {
    this.setState({ itemSelected: item });
    this.props.sendSelection(item, this.state.indexes[item]);
  }

  _renderDropdownItems(items) {
    let id = 0; // react requires unique key id for items

    return items.map((item) => {
      id += 1;
      return (
        <DropdownItem key={id} onClick={this._handleItemSelection.bind(this, item)}>
          {item}
        </DropdownItem>
      );
    });
  }

  _toggle() {
    if (this.props.toggle) {
      this.props.toggle();
    } else {
      this.setState({ open: !this.state.open });
    }
  }

  _isOpen() {
    return this.props.isOpen || this.state.open;
  }

  componentDidUpdate(prevProps) {
    if (this.props.placeholder !== prevProps.placeholder) {
      this.setState({ itemSelected: this.props.placeholder });
    }
  }

  render() {
    const { items, disabled = false } = this.props;
    let itemsToBeRendered = [];

    if (items.length > 0) {
      itemsToBeRendered = this._renderDropdownItems(items);
    }

    return (
      <div className={this.props.className}>
        <ReactstrapDropdown
          className="default-dropdown"
          isOpen={this._isOpen()}
          toggle={this._toggle.bind(this)}
        >
          <DropdownToggle color="default" disabled={disabled} caret>
            {this.state.itemSelected}
          </DropdownToggle>
          <DropdownMenu className="default-dropdown-menu">{itemsToBeRendered}</DropdownMenu>
        </ReactstrapDropdown>
      </div>
    );
  }
}

Dropdown.propTypes = {
  isOpen: PropTypes.bool, // determines whether dropdown is open or now
  toggle: PropTypes.func, // toggles dropdown isOpen prop
  sendSelection: PropTypes.func.isRequired, // callback handler which will do something with the selection
  items: PropTypes.array.isRequired, // array of dropdown items
  placeholder: PropTypes.string, // placeholder text when item has not yet been selected
  disabled: PropTypes.bool, // optionally disable dropdown
  open: PropTypes.bool,
  className: PropTypes.string,
};

export default Dropdown;
