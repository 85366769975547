import * as actions from "../actions/actionTypes.js";

const defaultState = {
  search: {
    result: [],
    selected: "",
  },
  processing: false,
  selectedCoDistribution: {},
  newCodistributionIsOpen: false,
  updateCoDistribution: false,
  deliveryClients: [],
  paperList: [],
  bundles: [],
};

const coDistributionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_CO_DISTRIBUTION_SEARCH: {
      const search = { ...state.search, result: action.searchResult };
      return { ...state, search };
    }

    case actions.SELECT_CO_DISTRIBUTION_SEARCH_RESULT: {
      const search = { ...state.search, selected: action.id };
      return { ...state, search };
    }

    case actions.CO_DISTRIBUTION_PROCESSING: {
      return { ...state, processing: action.flag };
    }

    case actions.LOAD_CO_DISTRIBUTION: {
      return { ...state, selectedCoDistribution: action.coDistribution };
    }

    case actions.TOGGLE_NEW_CO_DISTRIBUTION: {
      return {
        ...state,
        newCodistributionIsOpen: !state.newCodistributionIsOpen,
        updateCoDistribution: action.updateCoDistribution,
      };
    }

    case actions.LOAD_DELIVERY_CLIENTS: {
      return { ...state, deliveryClients: action.deliveryClients };
    }

    case actions.LOAD_PAPER_LIST: {
      return { ...state, paperList: action.paperList };
    }

    case actions.LOAD_BUNDLES: {
      return { ...state, bundles: action.bundles };
    }

    default:
      return state;
  }
};

export default coDistributionReducer;
