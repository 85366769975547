import { removeNullValues, resolveFieldValueFromObject } from "@utils/common.js";
import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";


export async function fetchAllInvoicingProgram() {
  const url = apiUrl(`invoice/monet_program`);

  return fetchWithRetry("getJson", [url])
    .then((InvoicingProgram = []) => {
      return InvoicingProgram;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchInvoicingProgram(invoicingProgram) {
  const url = apiUrl(`invoice/monet_program/${invoicingProgram.monetplanno}`);

  return fetchWithRetry("getJson", [url])
    .then((InvoicingProgram = []) => {
      return InvoicingProgram;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postInvoicingProgram(InvoicingProgram) {
  const url = apiUrl(`invoice/monet_program`);
  let payload = InvoicingProgram;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putInvoicingProgram(InvoicingProgram) {
  const url = apiUrl(`invoice/monet_program`);
  let payload = InvoicingProgram;

  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deleteInvoicingProgram(InvoicingProgram) {
  const url = apiUrl(`invoice/monet_program/${InvoicingProgram.monetplanno}/${InvoicingProgram.progdate}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}


export async function fetchInvoiceControl(invoiceControl) {
  const url = apiUrl(`invoice/plan_control/${invoiceControl.monetplanno}/${invoiceControl.invdate}`);

  return fetchWithRetry("getJson", [url])
    .then((InvoiceControl = []) => {
      return InvoiceControl;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchInvoiceProvision(invoiceProvision) {
  const url = apiUrl(`invoice/feep_control/${invoiceProvision.monetplanno}/${invoiceProvision.invdate}`);

  return fetchWithRetry("getJson", [url])
    .then((invoiceProvision = []) => {
      return invoiceProvision;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchInvoiceSingleControl(invoiceSingleControl) {
  const url = apiUrl(`invoice/single_control/${invoiceSingleControl.monetplanno}/${invoiceSingleControl.invdate}`);

  return fetchWithRetry("getJson", [url])
    .then((InvoiceSingleControl = []) => {
      return InvoiceSingleControl;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchSingleCampaigns(singleInvoiceCampaigns) {
  const url = apiUrl(`invoice/inv_camp/${singleInvoiceCampaigns.monetplanno}/${singleInvoiceCampaigns.invdate}`);

  return fetchWithRetry("getJson", [url])
    .then((singleInvoiceCampaigns = []) => {
      return singleInvoiceCampaigns;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchFeeGroups() {
  const url = apiUrl(`invoice/fee_group`);

  return fetchWithRetry("getJson", [url])
    .then((feeGroups = []) => {
      return feeGroups;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchFeepGroups(params) {
  const url = apiUrl(`invoice/feep_group/${params.monetplanno}/${params.invdate}`);
  // const url = apiUrl(`invoice/feep_group/3/2023-12-21/S1`);

  return fetchWithRetry("getJson", [url])
    .then((feeGroups = []) => {
      return feeGroups;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function fetchInvoiceReminder(params) {
  console.log({params})
  const url = apiUrl(`invoice/dun_control/${params.monetplanno}/${params.invdate}`);
  // const url = apiUrl(`invoice/feep_group/3/2023-12-21/S1`);

  return fetchWithRetry("getJson", [url])
    .then((reminder = []) => {
      return reminder;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postInvoiceControl(invoiceControl) {
  const url = apiUrl(`invoice/plan_control`);
  let payload = invoiceControl;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function postInvoiceProvision(invoiceProvision) {
  const url = apiUrl(`invoice/feep_control`);
  let payload = invoiceProvision;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postInvoiceSingleControl(invoiceSingleControl) {
  const url = apiUrl(`invoice/single_control`);
  let payload = invoiceSingleControl;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postInvoiceCampaigns(invoiceCampaigns) {
  const url = apiUrl(`invoice/inv_camp`);
  let payload = invoiceCampaigns;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function postInvoiceFeeGroups(invoiceFeeGroups) {
  const url = apiUrl(`invoice/feep_group`);
  let payload = invoiceFeeGroups;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
export async function postInvoiceReminder(invoiceReminder) {
  const url = apiUrl(`invoice/dun_control`);
  let payload = invoiceReminder;

  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}