import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TransitionGroup from "react-transition-group/TransitionGroup";

import { dismiss } from "@app/notification/notification.action.js";
import { selectNotifications } from "@app/notification/notification.selector.js";

import Notification from "./Notification.jsx";

import "@assets/styles/notification.scss";

/**
 * Global notification container. Listens to Redux store notification events and
 * displays notification at the top of the page.
 *
 * A notification has a content string and a type which affects the notification's
 * visual style.
 *
 * Notification format:
 * ```
 * message: 'message content string'
 * type: ['warning', 'error', 'success'] // one of
 * ```
 *
 * Showing a notification is done by using the `notify` action:
 * ```
 * store.dispatch(notify(message, type));
 * ```
 *
 * Component usage (should only be used once in `App.jsx`):
 * ```
 * import GlobalNotification from "...";
 *
 * <GlobalNotification />
 * ```
 */
const NotificationContainer = (props) => {
  const _renderNotifications = () => {
    return props.notifications.map((notification, index) => {
      return (
        <Notification
          key={notification.id}
          index={index}
          message={notification.message}
          type={notification.type}
          onDismiss={props.handleRemove.bind(this)}
          stayDuration={props.stayDuration}
        />
      );
    });
  };

  if (props.notifications.length === 0) {
    return null;
  }

  return (
    <div className="notification-container">
      <TransitionGroup>{_renderNotifications()}</TransitionGroup>
    </div>
  );
};

NotificationContainer.propTypes = {
  notifications: PropTypes.array.isRequired, // Notification objects in an array
  stayDuration: PropTypes.number.isRequired,
  handleRemove: PropTypes.func.isRequired, // Function to call when unmounting a notification
};

const mapStateToProps = (state) => {
  return {
    notifications: selectNotifications(state),
    stayDuration: Number(state.parameter.MESSAGE_DELAY.ALL.codevalue) || 3000,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleRemove: (i) => {
      dispatch(dismiss(i));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer);
