import React from "react";
import CSSTransition from "react-transition-group/CSSTransition";
import PropTypes from "prop-types";

import "@assets/styles/notification.scss";

/*
 * Animated global notification component.
 */

// Icons for notification boxes
const icons = {
  success: "ion-md-checkmark-circle",
  warning: "ion-md-warning",
  error: "ion-md-alert",
};
// Duration for the enter and exit animations
const animationDuration = 350;

const Animate = ({ children, ...props }) => (
  <CSSTransition {...props} timeout={animationDuration} classNames="slide" unmountOnExit={true}>
    {children}
  </CSSTransition>
);
Animate.propTypes = {
  children: PropTypes.node,
};

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      in: true,
    };
    this.timerId = null;
  }

  componentDidMount() {
    this.timerId = window.setTimeout(() => {
      this._disappear();
    }, this.props.stayDuration);
  }

  componentWillUnmount() {
    window.clearTimeout(this.timerId);
  }

  _disappear() {
    this.setState({ in: false });
  }

  _handleExit() {
    this.props.onDismiss(this.props.index);
  }

  render() {
    return (
      <Animate in={this.state.in} onExited={this._handleExit.bind(this)}>
        <div className={`notification ${this.props.type}`}>
          <span className={`notification-icon ${icons[this.props.type]}`}></span>
          <div className="notification-message">{this.props.message}</div>
          <span
            className="notification-button ion-ios-close"
            onClick={this._disappear.bind(this)}
          ></span>
        </div>
      </Animate>
    );
  }
}

Notification.propTypes = {
  onDismiss: PropTypes.func.isRequired, // Function to call when unmounting a notification
  index: PropTypes.number.isRequired, // Array index for the parent to know which notification to unmount
  type: PropTypes.string.isRequired, // Type of the notification (affects visual style)
  message: PropTypes.string.isRequired, // Message to display inside a notification
  stayDuration: PropTypes.number.isRequired,
};

export default Notification;
