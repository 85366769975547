import * as actionCreator from "@utils/actionCreator.js";

const SLICE = "locale";

export const SET_AVAILABLE_LOCALES = `${SLICE}/SET_AVAILABLE_LOCALES`;
export function setLocales(locales) {
  return actionCreator.createAction(SET_AVAILABLE_LOCALES, locales);
}

export const SET_LOCALE = `${SLICE}/SET_LOCALE`;
export function setLocale(locale) {
  return actionCreator.createAction(SET_LOCALE, locale);
}
