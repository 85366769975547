import { postImage } from "@app/imageBank/imageBank.effect.js";
import { notify } from "@app/notification/notification.action.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";
import * as effect from "./products.effect.js";
import { editProductText } from "@app/productTexts/productTexts.action.js";
import { addProductTexts } from "@utils/productTexts.js";

const SLICE = "products";

// Papercode actions

export const ADMIN_PRODUCT_IS_LOADING_PAPER_CODE_OPTIONS = `${SLICE}/IS_LOADING_PAPER_CODE`;
export const PUB_CLASSIFICATION1 = "PUB_CLASSIFICATION1";
export const PUB_CLASSIFICATION2 = "PUB_CLASSIFICATION2";
export const PUB_CLASSIFICATION3 = "PUB_CLASSIFICATION3";
export const ALL = "ALL";
export const ADMIN_PRODUCT_SET_CLASSIFICATION_OPTIONS = `${SLICE}/SET_CLASSIFICATION_OPTIONS`;
export const requestClassificationOptions = () => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_IS_LOADING_PAPER_CODE_OPTIONS))

  let classification1 = await effect.fetchClassificationOptions(PUB_CLASSIFICATION1, ALL);
  let classification2 = await effect.fetchClassificationOptions(PUB_CLASSIFICATION2, ALL);
  let classification3 = await effect.fetchClassificationOptions(PUB_CLASSIFICATION3, ALL);
  let businessUnitIds = await effect.fetchBusinessUnitIds();

  if (classification1.error) classification1 = [];
  if (classification2.error) classification2 = [];
  if (classification3.error) classification3 = [];
  if (businessUnitIds.error) businessUnitIds = [];

  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_CLASSIFICATION_OPTIONS, { classification1, classification2, classification3, businessUnitIds }));
};

//Product actions

export const ADMIN_PRODUCT_IS_LOADING_PRODUCTS = `${SLICE}/IS_LOADING_PRODUCTS`;
export const ADMIN_PRODUCT_SET_ALL_PRODUCTS = `${SLICE}/SET_ALL_PRODUCTS`;
export const requestAllProducts = (paperCode) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRODUCT_IS_LOADING_PRODUCTS));

  const response = await effect.fetchAllProducts(paperCode);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_ALL_PRODUCTS, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_ALL_PRODUCTS, response));
};

export const addProduct = (product, callback = () => { }) => async (dispatch) => {

  let descriptivetextid = await addProductTexts(product);
  if (descriptivetextid) {
    product.descriptivetextid = descriptivetextid;
    product.textid = descriptivetextid;
  }

  try {
    let imgUploadRes = await postImage(product.imageid);
    imgUploadRes = await imgUploadRes.json();
    product.imageid = imgUploadRes.imageid;
  }
  catch (e) { }

  const response = await effect.postProducts(product);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("productAdded"), "success"));
  dispatch(requestAllProducts(product.papercode));
};


export const ADMIN_PRODUCT_PRODUCT_EDITED = `${SLICE}/PRODUCT_EDITED`;
export const editProduct = (product, callback = () => { }) => async (dispatch, getState) => {

  // let imgUploadRes;
  // let isDefaultImage = product.imageid===null || product.imageid.id === 0;
  // if (isDefaultImage) {
  //   imgUploadRes = await postImage(product.imageid);
  // }
  // else {
  //   imgUploadRes = await putImage(product.imageid);
  // }

  // imgUploadRes = await imgUploadRes.json();
  // product.imageid = isDefaultImage ? imgUploadRes.imageid : product.imageid.id;

  if (!product.descriptivetextid || product.descriptivetextid == 0) {
    let descriptivetextid = await addProductTexts(product);
    if (descriptivetextid) {
      product.descriptivetextid = descriptivetextid;
      product.textid = descriptivetextid;
    }
  }
  else {
    dispatch(editProductText(product));
  }

  if (!product.textid || product.textid == 0) {
    product.textid = product.descriptivetextid;
  }

  const response = await effect.putProducts(product);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback();
  dispatch(notify(tr("productEdited"), "success"));
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_PRODUCT_EDITED, product));
  dispatch(requestAllProducts(product.papercode));

};

export const ADMIN_PRODUCT_SET_REMOVING_PRODUCT = `${SLICE}/SET_REMOVING_PRODUCT`;
export const ADMIN_PRODUCT_PRODUCT_REMOVED = `${SLICE}/PRODUCT_REMOVED`;
export const removeProduct = (prodcut, callback = () => { }) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_REMOVING_PRODUCT, true));

  const response = await effect.deleteProduct(prodcut);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_REMOVING_PRODUCT, false));
    return;
  }
  callback();
  dispatch(notify(tr("productRemoved"), "warning"));
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_REMOVING_PRODUCT, false));
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_PRODUCT_REMOVED, prodcut));
};

export const DIGIPRODUCT_TYPE = "DIGIPRODUCT_TYPE";
export const ADMIN_PRODUCT_SET_DIGIPRODUCT_TYPE = `${SLICE}/SET_DIGIPRODUCT_TYPE`;
export const ADMIN_PRODUCT_SET_PRODUCT_OPTIONS = `${SLICE}/SET_PRODUCT_OPTIONS`;

export const requestProductOptions = () => async (dispatch) => {

  let digiProductTypeOptions = await effect.fetchDigiProductType(DIGIPRODUCT_TYPE, ALL);
  let vatCodes = await effect.fetchVatCodes();

  if (digiProductTypeOptions.error) digiProductTypeOptions = [];
  if (vatCodes.error) vatCodes = [];

  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_PRODUCT_OPTIONS, { digiProductTypeOptions, vatCodes }));
};

export const CREDIT_CATEGORY = "PACKAGECATEGORIES";
export const ADMIN_PRODUCT_SET_PRODUCT_PACKAGES_OPTIONS = `${SLICE}/SET_PRODUCT_PACKAGES_OPTIONS`;
export const requestProductPackagesOptions = () => async (dispatch) => {
  let creditCategoryOptions = await effect.fetchCreditCategory(CREDIT_CATEGORY, ALL);
  if (creditCategoryOptions.error) creditCategoryOptions = [];
  dispatch(actionCreator.createAction(ADMIN_PRODUCT_SET_PRODUCT_PACKAGES_OPTIONS, { creditCategoryOptions }));
};