import moment from "moment";

import store from "@app/store.js";
import { setLocales, setLocale } from "@app/locale/locale.action.js";
import { notify } from "@app/notification/notification.action.js";
import { tr } from "./translation.js";
import { getUserData, setUserData } from "./user.js";

export const DATE_FORMATS = {
  "en-gb": "dd/MM/yyyy",
  fi: "dd.MM.yyyy",
  sv: "yyyy-MM-dd",
  de: "dd.MM.yyyy",
};

/*
 * General purpose functions for locale specific tasks.
 */

// Momentjs locales
const locales = ["en-gb", "de", "fi", "sv"];
// Kayak language codes and territories
export const langCodes = ["en-GB", "de-DE", "fi-FI", "sv-SE"];
// Mapping between kayak's and moment's language codes
const langCodeToLocale = {};
langCodes.forEach((value, index) => {
  langCodeToLocale[value] = locales[index];
});

// NOTE: Loading moment locales sometimes fails for no apparant reason.
// moment.updateLocale forcefully loads locales which, for reasons unknown, fixes locale switching.
for (const locale of locales) {
  const localeData = moment.localeData(locale);
  moment.updateLocale(locale, localeData);
}

// DateTime format used in Kayak web system
const dateTimeFmt = "YYYY-MM-DDTHH:mm:ss.SSSZ";
const dateFmt = "YYYY-MM-DD";
const timeFmt = "HH:mm:ss.SSSZ";

/**
 * Try to fetch user's preferred locale from the server. If locale has not been
 * set, try to read browser's locale. If even that fails, do nothing.
 */
export const initUserLocale = (callback = null) => {
  getUserData("locale").then((response) => {
    const locale = response !== null ? response : browserLocale();
    if (locales.includes(locale)) {
      moment.locale(locale);
      store.dispatch(setLocale(locale));
    }
    if (callback !== null) {
      callback();
    }
  });
};

/** Initialize applications locales. */
export const initLocales = () => {
  store.dispatch(setLocales(locales));
  store.dispatch(setLocale(locales[0]));
};

export const browserLocale = () => {
  return navigator.language.toLowerCase();
};

/** Generate moment customization object for a given language code. */
const _genTrData = (langCode) => {
  if (!(langCode in langCodeToLocale)) {
    return null;
  }

  const locale = langCodeToLocale[langCode];
  const keys = ["months", "monthsShort", "weekdays", "weekdaysShort", "weekdaysMin"];
  const result = {};

  for (const key of keys) {
    const localeData = moment.localeData(locale);
    if (localeData === null) {
      return null;
    }

    result[key] = localeData[key]();
  }

  return result;
};

/**
 * Update current locale's translations to match the current language.
 *
 * NOTE: This function needs to be called every time application's locale or translation
 * is changed and BEFORE Redux store locale change.
 */
export const updateLocale = (newTranslation, newLocale) => {
  const trCode = newTranslation || store.getState().translation.current;
  const currentLocale = store.getState().locale.current;
  const locale = newLocale || currentLocale;

  // Terminate locale change if the current language's locale is not supported
  const trData = _genTrData(trCode);
  if (trData === null) {
    store.dispatch(notify(tr("unsupportedLocale"), "error"));
    return;
  }

  // Revert previous moment language customization
  moment.updateLocale(currentLocale, null);
  // Set new moment language customization
  moment.updateLocale(locale, trData);
};

/** Switch the used locale. Return true on success and false on failure. */
export const switchLocale = (locale) => {
  if (moment.locale(locale) !== locale) {
    store.dispatch(notify(`${tr("failedToChangeTheLocaleTo")} ${locale}`, "error"));
    return false;
  }

  updateLocale(null, locale);
  setUserData("locale", locale);

  store.dispatch(setLocale(locale));

  store.dispatch(notify(`${tr("changedTheLocaleTo")} ${locale}`, "success"));

  return true;
};

/*
 * Functions for converting from kayak web format to local format
 */

/** Parse given datetime string and return a moment object. */
export const parseString = (string) => {
  return moment.parseZone(string, dateTimeFmt);
};

/** Parse string and return local date. */
export const parseDate = (string) => {
  return moment.parseZone(string, dateTimeFmt).format("L");
};

/** Parse string and return local time. */
export const parseTime = (string) => {
  return moment.parseZone(string, dateTimeFmt).format("LT");
};

export const parseDateTime = (string) => {
  return `${parseDate(string)} ${parseTime(string)}`;
};

export const parseDateTimeOrOnlyTime = (string) => {
  if (!moment(string).isValid()) {
    return moment.parseZone(string, timeFmt).format("LT");
  } else {
    return parseTime(string);
  }
};

/*
 * Function for converting from local format to kayak web format
 */

export const year1800ToString = () => {
  return moment("1800-01-01T00:00:00.000Z").format(dateTimeFmt);
};

export const year1800ToDateString = () => {
  return moment("1800-01-01T00:00:00.000Z").format(dateFmt);
};

export const year1800ToMoment = () => {
  return moment.parseZone("1800-01-01T00:00:00.000Z", dateTimeFmt);
};

export const year2078ToDateString = () => {
  return moment("2078-12-31T00:00:00.000Z").format(dateFmt);
};

export const year2078toMoment = () => {
  return moment.parseZone("2078-12-31T00:00:00.000Z", dateTimeFmt);
};

/** Convert current time into kayak web format. */
export const nowToString = () => {
  return moment().format(dateTimeFmt);
};

/** Convert given moment object into kayak web format.
 Added parseZone() for cross timezone compatibility ERP 11446 */
export const momentToString = (momentObj) => {
  return momentObj.parseZone().format(dateTimeFmt);
};

// Added parseZone() for cross timezone compatibility ERP 11446
export const momentToDateString = (momentObj) => {
  return momentObj.parseZone().format(dateFmt);
};

/** Convert given date string into kayak web format. */
export const dateToString = (dateStr) => {
  return moment(dateStr, "L").format(dateTimeFmt);
};

/** Convert given date and time strings into kayak web datetime format. */
export const dateTimeToString = (dateStr, timeStr) => {
  const dateTime = moment(dateStr, "L");
  const time = moment(timeStr, "LT");

  dateTime.set({ hour: time.hour(), minutes: time.minutes(), seconds: time.seconds() });

  return dateTime.format(dateTimeFmt);
};

/*
 * Functions to convert from moment object to display formatted string
 */

export const formatDate = (momentObj) => {
  return momentObj.format("L");
};

export const formatTime = (momentObj) => {
  return momentObj.format("LT");
};

/*
 * Miscellaneous
 */

export const now = () => {
  return moment();
};

export const tomorrow = () => {
  return moment().add(1, "days");
};

export const dateNow = () => {
  return moment().format("L");
};

export const dateTimeNow = () => {
  return moment().format("L LT");
};

export const today = () => {
  return parseString(momentToDateString(moment()));
};

export const prevWeekday = (date) => {
  let iLeapday = -1;
  const dayOfWeek = date.day();
  if (dayOfWeek === 1) {
    iLeapday = -3;
  } else if (dayOfWeek === 0) {
    // sunday
    iLeapday = -2;
  }

  return date.add(iLeapday, "days");
};

/**
 * Kayak has feature where user can write date in 'abbreviation' format and kayak automatically
 * figures out the correct date. E.g. when user enters 151218, this is converted into
 * 15.12.2018 when using fi locale. Each locale requires its own implementation.
 */
export const convertAbbreviationToDate = (abbreviation = "") => {
  const currentLocale = store.getState().locale.current;

  switch (currentLocale) {
    case "fi":
      return convertFi(abbreviation);
    default:
      return abbreviation; // Retrun input as-is if current locale doesn't have converter implemented
  }
};

/**
 * Converter for fi locale.
 */
const convertFi = (input) => {
  switch (input.length) {
    case 4:
      if (moment(input, "DDMM", "fi", true).isValid()) {
        return moment(input, "DDMM");
      }
      return null;
    case 5:
      if (moment(input, "DD.MM", "fi", true).isValid()) {
        return moment(input, "DD.MM");
      }
      return null;
    case 6:
      if (moment(input, "DDMMYY", "fi", true).isValid()) {
        return moment(input, "DDMMYY");
      } else if (moment(input, "DD.MM.", "fi", true).isValid()) {
        return moment(input, "DD.MM.");
      }
      return null;
    case 8:
      if (moment(input, "DDMMYYYY", "fi", true).isValid()) {
        return moment(input, "DDMMYYYY");
      } else if (moment(input, "DD.MM.YY", "fi", true).isValid()) {
        return moment(input, "DD.MM.YY");
      }
      return null;
    case 10:
      if (moment(input, "DD.MM.YYYY", "fi", true).isValid()) {
        return moment(input, "DD.MM.YYYY");
      }
      return null;
    default:
      return null;
  }
};

export const getDaysDiffBetweenDates = (dateInitial, dateFinal) => {
  return dateFinal.diff(dateInitial, "days");
};

// Build 3.6.4.2 (ERP #11784)
export const compareDates = (firstDate, secondDate) => {
  const date1 = firstDate || year1800ToMoment();
  const date2 = secondDate || year1800ToMoment();
  if (parseString(momentToDateString(date1)).isBefore(parseString(momentToDateString(date2)))) {
    return -1;
  } else if (
    parseString(momentToDateString(date1)).isAfter(parseString(momentToDateString(date2)))
  ) {
    return 1;
  } else {
    return 0;
  }
};

// Build 3.6.4.2 (ERP #11784)
export const dateEquals = (firstDate, secondDate) => {
  return compareDates(firstDate, secondDate) === 0;
};

export const dateTimeToStringWithFormat = (momentObj, format) => {
  return momentObj.format(format);
};

// Build 3.6.4.2 (ERP #11828)
export const nowToStringWithFormat = (format) => {
  return moment().format(format);
};
