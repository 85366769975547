import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import { connectRouter } from "connected-react-router";

import { history } from "./history.js";
import { CHANGE_PUBLISHER } from "../actions/actionTypes.js"; // ??? CHANGE TO USER

import addressReducer from "../reducers/addressReducer.js";
import adminUsersReducer from "./users/users.reducer.js";
import coDistributionReducer from "../reducers/coDistributionReducer.js";
import customerReducer from "../reducers/customerReducer.js";
import customerContactsReducer from "../reducers/customerContactsReducer.js";
import emailReducer from "./email/email.reducer.js";
import feedbackReducer from "../reducers/feedbackReducer.js";
import invoiceReducer from "../reducers/invoiceReducer.js";
import localeReducer from "./locale/locale.reducer.js";
import notificationReducer from "./notification/notification.reducer.js";
import parameterReducer from "../reducers/parameterReducer.js";
import subscriptionReducer from "../reducers/subscriptionReducer.js";
import translationsReducer from "./translations/translations.reducer.js";
import userReducer from "./user/user.reducer.js";
import versionReducer from "../reducers/versionReducer.js";
import adminParametersReducer from "./parameters/parameters.reducer.js";
import reportsReducer from "./reports/reports.reducer.js";
import productsReducer from "./products/products.reducer.js";
import imageBankReducer from "./imageBank/imageBank.reducer.js";
import productTextsReducer from "./productTexts/productTexts.reducer.js";
import priceReducer from "./price/price.reducer.js";
import invoicingReducer from "./invoice/invoicing/invoicing.reducer.js";


// Combine all reducers, rename them, and export them for the redux store to use.
const allReducers = combineReducers({
  form: reduxFormReducer,
  router: connectRouter(history),
  address: addressReducer,
  adminUsers: adminUsersReducer,
  coDistribution: coDistributionReducer,
  customer: customerReducer,
  customerContacts: customerContactsReducer,
  email: emailReducer,
  feedback: feedbackReducer,
  invoice: invoiceReducer,
  locale: localeReducer,
  notification: notificationReducer,
  parameter: parameterReducer,
  subscription: subscriptionReducer,
  translation: translationsReducer,
  user: userReducer,
  versionInformation:versionReducer,
  adminParameters:adminParametersReducer,
  reports:reportsReducer,
  products:productsReducer,
  imageBank:imageBankReducer,
  productTexts:productTextsReducer,
  price:priceReducer,
  invoicing: invoicingReducer
});

const appReducerWithRoot = (state, action) => {
  // When the current publisher is changed, all the data in the Redux store related
  // to the old publisher should be wiped out. This is achieved by giving undefined
  // as a state to reducers which forces them to their default state.
  if (action.type === CHANGE_PUBLISHER) {
    state = {
      ...state,
      form: undefined,
      customer: undefined,
      parameter: undefined,
      user: { ...state.user, defaults: {} },
    };
  }

  return allReducers(state, action);
};

export default appReducerWithRoot;
