import React from "react";
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from "reactstrap";
import PropTypes from "prop-types";

import { tr } from "@utils/translation.js";

/**
 * Dropdown list which displays user's previous history in the application.
 * Items in the list can be clicked to perform actions, such as displaying
 * information about recently created customer.
 */
class HistoryList extends React.Component {
  _toggleOpen() {
    this.setState({ open: !this.state.open });
  }

  _renderListItems(items) {
    const _selected = (item) => () => {
      this.props.handleSelection(item);
    };

    if (items.length > 0) {
      // Pick first 15 recents
      const filteredItems = items.slice(0, 15);

      return filteredItems.map((item, index) => {
        // #27314 Show cusno - customername in recent customer list
        return (
          <DropdownItem key={index} onClick={_selected(item)}>
            <i className="icon ion-ios-person history-list-icon" />
            {item.cusno + " - " + item.mixedcasename}
          </DropdownItem>
        );
      });
    } else {
      return <DropdownItem>{tr("noRecentHistory")}</DropdownItem>;
    }
  }

  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  render() {
    return (
      <div
        id="header-history"
        className={this.state.open ? "header-button active" : "header-button"}
      >
        <Dropdown nav isOpen={this.state.open} toggle={this._toggleOpen.bind(this)}>
          <DropdownToggle nav caret>
            <span>
              <i className="icon ion-md-time pr-2" />
              {tr("recentCustomers")}
            </span>
          </DropdownToggle>
          <DropdownMenu>
            {Object.keys(this.props.selectedCustomer).length !== 0 && (
              <span>
                <DropdownItem onClick={this.props.addCustomerToRecents}>
                  <i className="icon ion-ios-add history-list-icon" />
                  {tr("addCustomerToRecents")}
                </DropdownItem>
                <DropdownItem divider />
              </span>
            )}
            {this._renderListItems(this.props.recentCustomers)}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

HistoryList.propTypes = {
  handleSelection: PropTypes.func.isRequired, // A function for handling selection
  recentCustomers: PropTypes.array.isRequired, // A list of history items
  addCustomerToRecents: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.object.isRequired,
};

export default HistoryList;
