import { postImage } from "@app/imageBank/imageBank.effect.js";
import { notify } from "@app/notification/notification.action.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";
import * as effect from "./priceRow.effect.js";
// import { PRICE_LIST_STANDING_SUBSCRIPTION } from "@utils/constants.js";

const SLICE = "price";
const PRICE_LIST_STANDING_SUBSCRIPTION = "01";

export const ADMIN_PRICE_IS_LOADING_ALL_PRICE_ROW = `${SLICE}/IS_LOADING_ALL_PRICE_ROW`;
export const ADMIN_PRICE_SET_ALL_PRICE_ROW = `${SLICE}/SET_ALL_PRICE_ROW`;
export const requestPriceRowByPriceList = (priceRow) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_PRICE_ROW));

  const response = await effect.fetchPriceRow(priceRow);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_ALL_PRICE_ROW, []));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_ALL_PRICE_ROW, response));
};

export const ADMIN_PRICE_IS_LOADING_ALL_PERIODIC_PRICE_ROW = `${SLICE}/IS_LOADING_ALL_PERIODIC_PRICE_ROW`;
export const ADMIN_PRICE_SET_ALL_PERIODIC_PRICE_ROW = `${SLICE}/SET_ALL_PERIODIC_PRICE_ROW`;
export const requestPeriodicPriceRowByPriceList = (priceRow) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_PERIODIC_PRICE_ROW));

  const response = await effect.fetchPriceRow(priceRow);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_ALL_PERIODIC_PRICE_ROW, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_ALL_PERIODIC_PRICE_ROW, response));
};

export const addPriceRow = (priceRow, callback = () => { }) => async (dispatch) => {

  const response = await effect.postPriceRow(priceRow);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("priceRowAdded"), "success"));
  let getPriceRows = priceRow.subskind === PRICE_LIST_STANDING_SUBSCRIPTION ?
    requestPriceRowByPriceList : requestPeriodicPriceRowByPriceList
  dispatch(getPriceRows(priceRow));
};

export const ADMIN_PRICE_PRICE_ROW_EDITED = `${SLICE}/PRICE_ROW_EDITED`;
export const editPriceRow = (priceRow, callback = () => { }) => async (dispatch, getState) => {

  const response = await effect.putPriceRow(priceRow);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback();
  dispatch(notify(tr("priceRowEdited"), "success"));
  dispatch(actionCreator.createAction(ADMIN_PRICE_PRICE_ROW_EDITED, priceRow));
  let getPriceRows = priceRow.subskind === PRICE_LIST_STANDING_SUBSCRIPTION ?
    requestPriceRowByPriceList : requestPeriodicPriceRowByPriceList
  dispatch(getPriceRows(priceRow));

};

export const ADMIN_PRICE_SET_REMOVING_PRICE_ROW = `${SLICE}/SET_REMOVING_PRICE_ROW`;
export const ADMIN_PRICE_PRICE_ROW_REMOVED = `${SLICE}/PRICE_ROW_REMOVED`;
export const removePriceRow = (priceRow, callback = () => { }) => async (dispatch) => {
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_REMOVING_PRICE_ROW, true));

  const response = await effect.deletePriceRow(priceRow);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_REMOVING_PRICE_ROW, false));
    return;
  }
  callback();
  dispatch(notify(tr("priceRowRemoved"), "warning"));
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_REMOVING_PRICE_ROW, false));
  dispatch(actionCreator.createAction(ADMIN_PRICE_PRICE_ROW_REMOVED, priceRow));
};