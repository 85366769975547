/*
 * Application's translation data.
 *
 * Each translation should have an id which denotes a translation string in
 * Jaicom's database. However, database's translations are not complete nor
 * always satisfactory so an approriate id doesn't always exist. The id can be
 * omitted from the follwing translations, which causes the fallback value to be
 * used instead.
 */

const translationData = {
  abortAddingCurrentProduct: { id: 43328 },
  abortChanges: { id: 43364 },
  abortSubscription: { id: 95 },
  account: { id: 203 },
  accountHolder: { id: 42807 }, // #28738
  accountInfo: { id: 31995 },
  accountMissing: { id: 42942 }, // #32668
  accountMissingAskConitnue: { id: 3713 }, // #32668
  accountMissingHyphen: { id: 43923 }, // Build 3.6.4.2 (Issue #33443)
  activateDate: { id: 922 },
  active: { id: 110 },
  activeDate: { id: 43365 },
  activeSubs: { id: 139 },
  activeSubsInAddress: { id: 10675 },
  activeSubsCannotBeRemoved: { id: 10198 },
  actualPrice: { id: 41641 },
  add: { id: 196 },
  addCustomerToRecents: { id: 43366 },
  additionalInformation: { id: 4657 },
  additionalName: { id: 42149 },
  additionalSubscriptionInformation: { id: 43295 }, // #29170
  addNewDynamicProduct: { id: 44291 }, // Build 4.1.1.0 (ERP #11124)
  addNewExtraProduct: { id: 43290 }, // #29170
  addNewSalesman: { id: 43350 },
  addPaper: { id: 43289 }, // #29170
  addProductToSubs: { id: 43329 },
  advertisingRestrictions: { id: 325 },
  agentCorrespondence: { id: 3756 },
  allFields: { id: 4678 },
  addFile: { id: 40576 },
  addProducts: { id: 43367 },
  addressNotInDI: { id: 42145 }, // #33092
  addressFocusings: { id: 40050 },
  address: { id: 23 },
  address2: { id: 512 },
  address3: { id: 513 },
  addressed: { id: 821 },
  addressEnd: { id: 31478 },
  addresses: { id: 43311 },
  addressesForChosenSubs: { id: 3371 },
  addressFocusingFailed: { id: 1007 },
  addressInfo: { id: 10339 },
  addressIsUnfocused: { id: 3280 },
  addressLabel: { id: 519 },
  addressMaterial: { id: 30974 },
  addressMaterials: { id: 1116 },
  addressMissing: { id: 44195 }, // Build 3.6.4.2 (ERP #11110)
  addressNo: { id: 40807 },
  addrNo: { id: 3356 }, // Build 3.6.4.1 (Issue #33098)
  addressOnMap: { id: 296 },
  addressRows: { id: 516 },
  addressSpecifiers: { id: 10703 },
  addressStart: { id: 31477 },
  addressState: { id: 3721 },
  addressTwo: { id: 30390 },
  addressFormat: { id: 43368 },
  addressValidationAcceptedAddress: { id: 10387 },
  addressStatePending: { id: 186 },
  addressStateActive: { id: 139 },
  addressStatePassive: { id: 112 },
  addressStateEnded: { id: 141 },
  addToSubscription: { id: 43325 },
  additionalRow: { id: 708 },
  advancedSearch: { id: 10348 },
  advancePayment: { id: 856 },
  advanceTaxReg: { id: 438 },
  agreedPrice: { id: 3567 },
  all: { id: 18 }, // #27423, change textno from 32445 to 18
  allowed: { id: 3676 },
  allowProfilingUpdateFailed: { id: 43369 },
  allAddresses: { id: 3744 },
  allCustomerContacts: { id: 10820 },
  allFeedbacks: { id: 3287 },
  allowProfiling: { id: 42490 },
  alternativeInvoice: { id: 40530 },
  amount: { id: 43370 },
  annualPrice: { id: 3660 },
  apartment: { id: 30855 },
  apart: { id: 905 }, // Build 3.6.4.2 (Issue #33117)
  apply: { id: 43371 },
  applyCustomerSpecificSubsType: { id: 43372 },
  areYouSure: { id: 10648 }, // #27311, add are you sure for messagebox text
  areYouSureToAbortAddressChange: { id: 758 },
  areYouSureToAbortFeedback: { id: 3087 },
  areYouSureToAbortNewSubscription: { id: 495 },
  areYouSureToAbortExtraProducts: { id: 43333 },
  areYouSureToAbortRenewal: { id: 43315 },
  areYouSureToDeleteAddressLabel: { id: 43373 },
  areYouSureToDeleteCustomerContact: { id: 10812 },
  areYouSureToDeleteCustomerEvent: { id: 10814 },
  areYouSureToDeleteCustomerLetter: { id: 40251 },
  areYouSureToDeleteCustomerProfile: { id: 43374 },
  areYouSureToDeleteCustomerAddress: { id: 43375 },
  areYouSureToDeleteExtraProduct: { id: 43376 },
  areYouSureToDeleteSalesman: { id: 43353 },
  areYouSureToDeleteSalesmanPrize: { id: 43377 },
  areYouSureToDeleteSingleSubsCustomer: { id: 43378 },
  areYouSureToSendAutogiro: { id: 10690 }, // #28738, send autogiro question
  areYouSureToSendEmailToCustomer: { id: 40732 }, // Build 3.6.4.2 (Issue #6661)
  areYouSureYouWantToCancel: { id: 3122 },
  areYouSureYouWantToRemoveSubs: { id: 3685 },
  arguments: { id: 464 },
  association: { id: 137 },
  attachments: { id: 30690 },
  authenticationRequired: { id: 43379 },
  autogiro: { id: 154 }, // #27311, autogiro datagrid title text
  autogiroSent: { id: 40804 }, // #28738, autogiro sent message
  autogiroUtanMedg: { id: 41400 }, // Build 3.6.4.2 (Issue #33344)
  availableAddresses: { id: 781 },
  availableLabelsForSelectedAddress: { id: 43361 },
  avgPrice: { id: 43380 },
  balance: { id: 859 },
  bankAccountNo: { id: 945 },
  bannerQuantity: { id: 472 },
  bannerType: { id: 3723 },
  basedOnPriceList: { id: 3715 },
  basicAddress: { id: 43381 },
  basicAddresses: { id: 751 },
  basicAddressStart: { id: 31484 }, // Build 3.6.4.2 (ERP #12580)
  basicCustomerDetails: { id: 43382 },
  basicData: { id: 30478 },
  basicInfo: { id: 43383 },
  basicPeriod: { id: 470 },
  bettingPlace: { id: 3769 },
  birthday: { id: 43384 },
  bookCreditLoss: { id: 40914 }, // #11133
  bookCreditLossQuestion: { id: 43956 }, // #11133
  bookCreditLossFailed: { id: 15345 },
  bookCreditLossSucceeded: { id: 10695 },
  bookingDate: { id: 603 },
  branch: { id: 3762 },
  bundle: { id: 802 },
  bundleName: { id: 803 },
  calculate: { id: 3667 },
  campaign: { id: 208 },
  campaignChannel: { id: 41848 },
  campaignCoProducts: { id: 43385 },
  campaignGroup: { id: 209 },
  campaignId: { id: 2000 },
  campaignSubscriptions: { id: 213 },
  campaignTotalPrice: { id: 2005 },
  campaignValidTime: { id: 2007 },
  campaignStartDateLastBeforeNextIssueDate: { id: 41262 }, // Build 3.6.4.2 (Issue #33221)
  campaignDateLimitNotMatchStartDate: { id: 10313 }, // Build 3.6.4.2 (Issue #33221)
  canBeUsedAsCreditInInvoicing: { id: 32332 },
  cancel: { id: 508 },
  cancelAtEndOfItem: { id: 3571 },
  cancelled: { id: 191 },
  cancellingDatePaperStillDelivered: { id: 3572 },
  cancellingFee: { id: 40649 },
  cancelPlusCredit: { id: 41310 },
  cancelRemoved: { id: 10489 },
  cancelReason: { id: 3128 },
  cancelReasonGroup: { id: 42132 },
  cancelSubscription: { id: 95 },
  cancelSubsCanNotRemoveExtension: { id: 40959 }, // Build 3.6.4.2 (ERP #11277)
  cancelSleepSubsConfirm: { id: 40780 }, // Build 3.6.4.1 (Issue #33108)
  cannotDeleteCustomer: { id: 32025 },
  carrierDistrict: { id: 30477 },
  carrierDistrictNotFound: { id: 43386 },
  cashier: { id: 606 },
  cashiersQty: { id: 3759 },
  category: { id: 3487 },
  category2: { id: 3249 },
  centralFirmCode: { id: 3764 },
  channel: { id: 30868 },
  change: { id: 15 },
  changeActiveDate: { id: 3599 },
  changeAddress: { id: 40075 },
  changeAddressValidityPeriod: { id: 3552 },
  changeAllocation: { id: 333 },
  changedAt: { id: 3586 },
  changeLanguage: { id: 3005 },
  changedWith: { id: 3588 },
  changeDueDate: { id: 933 },
  // #30272
  changeDueDateByInvoice: { id: 40682 },
  changeEmail: { id: 43387 },
  changeHolidayAddressToBasic: { id: 767 }, // Build 3.6.3.4 (Issue #32668)
  changeHolidayAddressToBasicFailed: { id: 10169 }, // Build 3.6.3.4 (Issue #32668)
  changeHolidayAddressToBasicExistsOverlapp: { id: 40500 }, // Build 3.6.3.4 (Issue #32668)
  changeHolidayAddressToBasicQuestion: { id: 40104 }, // Build 3.6.3.4 (Issue #32668)
  changeOfAddress: { id: 87 },
  changePayer: { id: 428 },
  changeRecommender: { id: 429 },
  changeSalesman: { id: 430 },
  changesSaved: { id: 43388 },
  changeStartDate: { id: 4035 },
  changeStateToActive: { id: 41234 },
  changeStateToEnded: { id: 41233 },
  changeType: { id: 921 },
  changedTheLocaleTo: { id: 43389 },
  changeImmediately: { id: 40770 },
  checkAll: { id: 41169 },
  checkInvalidFields: { id: 43390 },
  chooseCustomer: { id: 11 },
  chooseSubsForUpdatingInvMode: { id: 40779 },
  city: { id: 3028 },
  citydistrict: { id: 32423 },
  cityDistrict: { id: 41643 },
  classification: { id: 217 },
  clear: { id: 3618 },
  clearSettingsExplanation: { id: 43391 },
  clearSettings: { id: 43392 },
  clickToEdit: { id: 43393 },
  close: { id: 31867 },
  closed: { id: 3093 },
  closed2: { id: 3771 }, // Build 3.6.4.2 (Issue #33447)
  collectInvoice: { id: 443 },
  coDistribution: { id: 3094 },
  coDistributionCustomers: { id: 20 }, // Build 4.0.4.0 (ERP #10162)
  coDistributionFeedback: { id: 10593 },
  commission: { id: 230 },
  commissionPaid: { id: 43318 },
  commitmentDate: { id: 41634 },
  commitmentPeriod: { id: 41630 },
  common: { id: 30909 },
  commonCode: { id: 43363 },
  commune: { id: 42109 },
  communeCode: { id: 3352 }, // Build 3.6.4.2 (Issue #33322)
  company: { id: 136 },
  companyIDInvalid: { id: 44191 }, // Build 3.6.4.2 (ERP #11080)
  companyName: { id: 42194 },
  compensation: { id: 54 },
  compensationToSubscription: { id: 10300 },
  compensationType: { id: 32349 },
  completionTime: { id: 10279 },
  completeOrRemoveIncompleteSubsProduct: { id: 43394 },
  confirm: { id: 43395 },
  confirmationNeededForTask: { id: 3052 },
  confirmDelete: { id: 41689 },
  confirmDeleteFeedback: { id: 10477 },
  confirmStatus: { id: 43396 },
  consolidatedInvoice: { id: 211 },
  consolidatedInvoiceDetails: { id: 3825 },
  contact: { id: 10825 },
  contactDate: { id: 10796 },
  contactEarliest: { id: 10794 },
  contactHistory: { id: 40264 },
  contactId: { id: 10797 },
  contactLatest: { id: 10795 },
  contactInfo: { id: 41070 },
  contactInformation: { id: 929 },
  contactInfoSavedToReserveEvents: { id: 43397 },
  contactType: { id: 40760 },
  contactTypeCarma: { id: 41299 }, // Build 3.6.3.4 (Issue #32668)
  contactTypeServicePlus: { id: 42645 }, // Build 3.6.3.4 (Issue #32668)
  contactTypeAdobe: { id: 42646 }, // Build 3.6.3.4 (Issue #32668)
  contactTypeApsis: { id: 42647 }, // Build 3.6.3.4 (Issue #32668)
  contactTypeNeolane: { id: 42648 }, // Build 3.6.3.4 (Issue #32668)
  continue: { id: 43398 },
  continueToSave: { id: 15108 }, // Build 3.6.4.2 (Issue #33221)
  controlCopy: { id: 183 },
  controlData: { id: 3434 },
  controlText: { id: 3340 },
  couldNotChangeInvoiceMode: { id: 10368 },
  couldNotCreateInvoiceMode: { id: 10366 },
  couldNotDeleteInvoiceMode: { id: 10364 },
  couldNotRemoveSleep: { id: 10355 },
  couldNotUpdateSuspend: { id: 43399 },
  couldNotSuspendDelivery: { id: 10370 },
  countInAbc: { id: 3338 },
  countingMoney: { id: 3507 },
  countingTime: { id: 3506 },
  countries: { id: 30004 },
  country: { id: 25 },
  countryCode: { id: 3051 },
  countryCodeIsRequiredForAddressThreeLines: { id: 3393 }, // #33092
  countryCodeOrZipcodeIsRequired: { id: 3391 }, // #33092
  countryName: { id: 10778 },
  copyCurrentAddress: { id: 41530 },
  copyCurrentPayer: { id: 43400 },
  copySubsAsTemplate: { id: 40285 },
  createCreditInvoice: { id: 3535 },
  createDeliveryChange: { id: 3824 },
  createInvoice: { id: 43401 },
  createNewCustomer: { id: 43172 }, // #27423
  credit: { id: 31203 },
  creditAmountDaysOrMoney: { id: 43402 },
  credited: { id: 3515 },
  creditPrinted: { id: 3516 }, // Build 3.6.4.2 (Issue #33117)
  creditedOnInvoice: { id: 3520 },
  creditTheInvoice: { id: 3533 },
  creditType: { id: 3505 },
  creditingReason: { id: 42133 },
  creditingStatus: { id: 3513 },
  creditInMoney: { id: 40901 },
  creditInTime: { id: 40902 },
  creditInvoice: { id: 100 },
  creditInvoice2: { id: 839 }, // #30420
  creditPartlyPaidInvoiceConfirm: { id: 42921 }, // Build 3.6.4.2 (Issue #33322)
  creditingInvoiceFailed: { id: 10433 },
  creditSelectedRow: { id: 3534 },
  creatingBasicAddressFailed: { id: 43403 },
  creatingBasicAddressSucceeded: { id: 43404 },
  creatingCoDistributionFailed: { id: 43405 },
  creatingExtraEditionSubsFailed: { id: 43406 },
  creatingImmediateInvoiceFailed: { id: 43407 },
  creatingInvoiceAddressFailed: { id: 43408 },
  creatingMarketingAllowanciesFailed: { id: 43409 },
  creatingNewCustomerFailed: { id: 4847 },
  creatingNewDynamicProductDetailsFailed: { id: 44353 }, // Build 4.1.1.0 (ERP #11124)
  creatingNewSubscriptionFailed: { id: 43410 },
  creatingRefundFailed: { id: 43411 },
  creatingRenewalFailed: { id: 43412 },
  creatingSplitAddressFailed: { id: 43413 },
  creatingTempAddressFailed: { id: 43414 },
  criteriaAppliesToPayer: { id: 840 },
  currencies: { id: 218 },
  currentBasicAddress: { id: 752 },
  currentDeliveryToDoorAddresses: { id: 43360 },
  currentInvoicingAddress: { id: 3450 },
  cusno: { id: 48 },
  customer: { id: 274 },
  customerAddresses: { id: 3720 },
  customerAddressDeleted: { id: 43415 },
  customerAddressDeleteFailed: { id: 43416 },
  customerAddressUpdated: { id: 43417 },
  customerAddressUpdatedFailed: { id: 43418 },
  customerAddressReadingFailed: { id: 10136 }, // Build 3.6.3.4 (Issue #32668)
  customerAddressAddingFailed: { id: 10137 }, // Build 3.6.3.4 (Issue #32668)
  customerContacts: { id: 10614 },
  customerContactsFetchFailed: { id: 43419 },
  customerContactHistory: { id: 43420 },
  customerContactRemoved: { id: 43421 },
  customerContactRemoveFailed: { id: 10799 },
  customerContactSaved: { id: 10811 },
  customerContactSaveFailed: { id: 10798 },
  customerContactTaskForYou: { id: 10809 },
  customerDataSharing: { id: 267 },
  customerDataWasNotSuccessfullySaved: { id: 43483 },
  customerDoesNotHaveCampaignInvmode: { id: 43484 },
  customerEventRemoveFailed: { id: 10808 },
  customerEventRemoved: { id: 43485 },
  customerEventSaved: { id: 10813 },
  customerEventSaveFailed: { id: 10807 },
  customerFeedback: { id: 10593 },
  customerHasAutogiroSubscriptions: { id: 40694 }, // Build 3.6.4.1 (Issue #33117)
  customerHasHadSubsInLastMonth: { id: 41883 },
  customerHasMoreActiveSubsForCancelling: { id: 40785 },
  customerHasOpenInvoices: { id: 10677 },
  customerHasSameInvoiceMode: { id: 3406 },
  customerHasSamePaperAlreadyOrdered: { id: 43486 },
  customerHasUnpaidSubscriptions: { id: 10678 },
  customerHasDeniedSubscriptions: { id: 43278 }, // ERP #10146
  customerHasUnpaidStoppedSubscriptions: { id: 40266 }, // Build 3.6.4.2 (Issue #33625)
  customerHistory: { id: 40048 }, // #29630
  customerHistoryStoreFailed: { id: 10138 }, // Build 3.6.3.4 (Issue #32668)
  customerInformation: { id: 10, fallback: "Customer information" },
  customerIsBecomingNewPayerOfOtherSubs: { id: 41501 }, // Build 3.6.2.2 (Issue #31348)
  customerIsAlsoSalesman: { id: 340 }, // Build 3.6.2.2 (Issue #31348)
  customerHasActiveSubs: { id: 43136 }, // Build 3.6.2.2 (Issue #31348)
  customerHasExtraProdReg: { id: 341 }, // Build 3.6.2.2 (Issue #31348)
  customerHasOverpayment: { id: 42951 }, // Build 3.6.2.2 (Issue #31348)
  customerHasSubsStopRecently: { id: 339 }, // Build 3.6.2.2 (Issue #31348)
  customerHasSubsStopRecentlyConfirm: { id: 42943 }, // Build 3.6.2.2 (Issue #31348)
  customerHasToDoorServiceReg: { id: 342 }, // Build 3.6.2.2 (Issue #31348)
  customerLetters: { id: 40261 },
  customerList: { id: 10672 },
  customerName: { id: 31457 },
  customerNotFound: { id: 43463 },
  customerNumber: { id: 219 },
  customerProfileDeleted: { id: 43465 },
  customerProfileDeleteFailed: { id: 43466 },
  customerProfileUpdated: { id: 43467 },
  customerProfileUpdateFailed: { id: 43468 },
  customerProfile: { id: 30197 },
  customerProfiles: { id: 10499 },
  customerReserve: { id: 43343 }, // #29630
  customerSavedSuccessfully: { id: 40866 },
  customerSpecificInvAddress: { id: 10714 },
  customerSpecificSubscriptionType: { id: 43469 },
  customerReadFailed: { id: 10115 },
  customerRemoved: { id: 925 },
  customerStatus: { id: 220 },
  customerType: { id: 222 },
  dataSharing: { id: 267 },
  dateIsRequired: { id: 10378 },
  dates: { id: 43355 },
  date: { id: 3374 },
  day: { id: 31611 },
  dayDelivery: { id: 42148 },
  days: { id: 3508 },
  daysAb: { id: 3664 }, // Build 3.6.4.1 (Issue #33117): abbreviation of days
  dayPriceExclVat: { id: 3631 }, // Build 3.6.3.4 (Issue #32668)
  dayPriceIncVat: { id: 42685 }, // Build 3.6.3.4 (Issue #32668)
  dbConnectFailure: { id: 40273, fallback: "Connection to the server failed." },
  dealer: { id: 3755 },
  debtPaid: { id: 192 },
  deductedExpenses: { id: 40743 },
  default: { id: 3474 },
  defaultInvoiceMode: { id: 3469 },
  defaultMessage: { id: 3073 },
  delete: { id: 97 },
  deleteAddress: { id: 43461 },
  deleteAll: { id: 30552 },
  deleteAllSalesPersonGroups: { id: 43460 },
  deleteCustomer: { id: 83 },
  deleteCustomerContact: { id: 10818 },
  deleteCustomerEvent: { id: 10823 },
  deleteCustomerProfile: { id: 43176 }, // #27423
  deleteDeliveryToDoor: { id: 43462 },
  deleteExtraProduct: { id: 43459 },
  deleteHolidayAddress: { id: 43357 },
  deleteLetter: { id: 43458 },
  deletePaperSpecificFeeData: { id: 43487 },
  deletePendingAddress: { id: 43356 },
  deletePendingInvoiceAddress: { id: 43470 },
  deleteProductFromSubs: { id: 43324 }, // #29170
  deleteSingleSubsCustomer: { id: 43471 },
  deleteSplitAddress: { id: 783 },
  deletingAddressLabelFailed: { id: 43488 },
  deletingBasicAddressFailed: { id: 43489 },
  deletingDeliveryToDoorFailed: { id: 43490 },
  deletingExtraChargeFailed: { id: 43491 },
  deletingInvoiceAddressFailed: { id: 43492 },
  deletingMarketingAllowanceFailed: { id: 43493 },
  deletingSelectedContactInfoFailed: { id: 43494 },
  deletingSingleSubsCustomerFailed: { id: 43495 },
  deleteSelectedContactInfo: { id: 43189 }, // #27423
  deleteSelectedInvoiceMode: { id: 43472 },
  deleteSelectedMarketingAllowancy: { id: 43473 },
  deleteSelectedPaperDiscount: { id: 43474 },
  deleteSelectedPaperSubsType: { id: 43475 },
  deleteSelectedPostOffice: { id: 10784 },
  deletingPaperDiscountFailed: { id: 43496 },
  deletingPostOfficeFailed: { id: 10785 },
  deletingSalesmanFailed: { id: 43497 },
  deletingSalesmanPaperDataFailed: { id: 43498 },
  deletingSalesmanPrizeFailed: { id: 43499 },
  deletingSalesPersonGroupsFailed: { id: 43500 },
  deletingSplitAddressFailed: { id: 43501 },
  deletingTempAddressFailed: { id: 43502 },
  delivery: { id: 1115 },
  deliveryChangeBlock: { id: 3767 },
  deliveryChanges: { id: 920 },
  deliveryCustomer: { id: 32215 },
  deliveryEnds: { id: 3802 },
  deliveryHistory: { id: 939 },
  deliveryInformation: { id: 826 },
  deliveryIssues: { id: 30377 },
  deliveryMax: { id: 43335 },
  deliveryMessage: { id: 55 },
  deliveryMessageDate: { id: 3072 },
  deliveryMessageEnd: { id: 3795 },
  deliveryMessageStart: { id: 3794 },
  deliveryMethod: { id: 41345 },
  deliveryNote: { id: 43457 },
  deliveryReady: { id: 40647 },
  deliverySuspensionCreated: { id: 10354 },
  deliveryStarts: { id: 3801 },
  deliverySuspensionUpdated: { id: 43503 },
  deliveryTime: { id: 3803 },
  deliveryToDoor: { id: 3362 },
  deliveryToDoorSaveFailed: { id: 43504 },
  denied: { id: 3677 },
  department: { id: 463 },
  description: { id: 779 },
  details: { id: 3583 },
  diAPICallFailed: { id: 43757 }, // #33092
  direction: { id: 10800 },
  directSale: { id: 239 },
  discardChanges: { id: 43456 },
  discount: { id: 3468 },
  discountLimit: { id: 43455 },
  discpercent: { id: 225 },
  distribOrg: { id: 42184 },
  distribution: { id: 1114 },
  distributionAddress: { id: 3361 },
  distributionDays: { id: 31801 },
  distributionFeedback: { id: 3069 },
  distributionInformation: { id: 42205 },
  distributionOrganization: { id: 30544 },
  distributionState: { id: 3848 },
  distributionType: { id: 258 },
  distributionsCode: { id: 43617 }, // Build 4.1.2.0 (ERP #12497)
  distrikt: { id: 15097 },
  donation: { id: 41844 },
  donationCusno: { id: 41623 },
  donationFocusno: { id: 30568 },
  donationNotAvailable: { id: 41682 }, // Build 4.1.1.0 (ERP #11916)
  donationTarget: { id: 41623 },
  doneAlready: { id: 3079 },
  dontApply: { id: 43454 },
  dontRenewSubscription: { id: 42839 }, // #25217 // Yabing: seems 43737 is wrong, should use 42839
  doorCode: { id: 40645 },
  doorNo: { id: 3091 },
  doYouWantToAddNewSubs: { id: 3615 },
  doYouWantToCancelUpdatingCustomer: { id: 3151 },
  doYouWantToChangeTheStateOfAdvPayment: { id: 32333 },
  doYouWantToChangeExpDateOfInvargItem: { id: 44193 }, // Build 3.6.4.2 (ERP #11106)
  doYouWantToContinueCreateNewSubs: { id: 40422 }, // Build 3.6.4.2 (Issue #33625)
  doYouWantToKeepSalesDate: { id: 10732 },
  doYouWantToStopCancellingSubscription: { id: 3555 },
  doYouWantToContinueSaving: { id: 3107 },
  doYouWantToRemoveCancellation: { id: 3834 },
  doYouWantToRemoveRepaymentPaydate: { id: 41185 },
  doYouWantToRemoveRowFromPool: { id: 3331 },
  doYouWantToRemoveHolidayAddress: { id: 3690 }, // Build 3.6.4.2 (ERP #11199)
  dueDate: { id: 229 },
  dueDateIsDay: { id: 3466 },
  duplicatedCustomer: { id: 3466 },
  duplicates: { id: 40077 },
  dropPoint: { id: 3375 },
  dropPoint2: { id: 3339 }, // Build 3.6.4.2 (Issue #33447)
  dropPointSaturday: { id: 41469 }, // Build 3.6.4.2 (Issue #33447)
  dynamicProductDetails: { id: 44292 }, // Build 4.1.1.0 (ERP #11124)
  dynamicProductInformation: { id: 44358 }, // Build 4.1.1.0 (ERP #11124)
  dynamicProductSubscriptionHasAlreadyBeenCancelled: { id: 44360 }, // Build 4.1.1.0 (ERP #11124)
  earliestInvoiceDate: { id: 40610 },
  earlyDelivery: { id: 32190 },
  edit: { id: 32133 },
  editFailedExtraProductsAndTryAgain: { id: 43505 },
  editHolidayAddress: { id: 43453 },
  editInvoicingAddress: { id: 43452 },
  edition: { id: 30015 },
  electronicPayment: { id: 3524 },
  email: { id: 29 },
  emailAddress: { id: 3077 },
  emailBody: { id: 43451 },
  emailChanged: { id: 43506 },
  emailChangeFailed: { id: 43507 },
  emailChangeRequestFailed: { id: 43508 },
  emailChangeRequestSend: { id: 43509 },
  emailCusContact: { id: 227 },
  emailSentSuccessfully: { id: 43476 },
  emailTitle: { id: 41296 },
  emails: { id: 41907 },
  empty: { id: 40648 },
  emptyAndNew: { id: 830 }, // Build 3.6.4.2 (ERP #11199)
  end: { id: 30537 },
  ends: { id: 62 },
  endDate: { id: 281 },
  ending: { id: 763 }, // Build 3.6.4.2 (Issue #33117)
  endDateDisallowedToBeBeforeStartDate: { id: 42014 },
  endDoorDelivery: { id: 43510 },
  ended: { id: 42160 },
  enterNewExpDate: { id: 935 },
  enteredTotalPrice: { id: 3726 }, // #33445
  entryDate: { id: 602 },
  entryWrittenBy: { id: 3092 },
  errorSavingSubscription: { id: 43511 },
  errorOccuredWhileCheckingVat: { id: 43512 },
  errorOccuredWhileSavingCustomerData: { id: 43513 },
  even: { id: 42710 },
  eventCode: { id: 41814 },
  eventDate: { id: 10392 },
  eventStatus: { id: 10393 }, // #27311, column title text
  eventTypeGroupCode: { id: 43647 }, // #30466, eventtypegroupcode
  processDate: { id: 10394 }, // #27311, column title text
  oldSocialsecno: { id: 10395 }, // #27311, column title text
  newSocialsecno: { id: 10396 }, // #27311, column title text
  oldAccountnumber: { id: 10397 }, // #27311, column title text
  newAccountnumber: { id: 10398 }, // #27311, column title text
  oldInvmode: { id: 10399 }, // #27311, column title text
  newInvmode: { id: 10400 }, // #27311, column title text
  newPeriod: { id: 496 }, // #18896, title for new period
  eventInformation: { id: 3737 },
  eventKey: { id: 32221 },
  events: { id: 40434 },
  eventType: { id: 10611 },
  expDate: { id: 10214 },
  expDateUpdated: { id: 43514 },
  expDateUpdateFailed: { id: 43515 },
  expectedReadyTime: { id: 42203 },
  expenses: { id: 705 },
  expensesMoreThanSumWarning: { id: 43181 }, // 32668 old: 43742, #32663, expense more than sum in repayment form
  express: { id: 3776 },
  extra: { id: 823 },
  extraExpenseItemDeleted: { id: 10435 },
  extraExpenseItemDeletionFailed: { id: 10436 },
  extension: { id: 66 },
  extensionUnpaid: { id: 195 },
  externalCusno: { id: 226 },
  externalSentDate: { id: 43607 }, // Build 4.1.2.0 (ERP #12497)
  extraCharge: { id: 43450 },
  extraChargeDeleted: { id: 43516 },
  extraCharges: { id: 3635 },
  extraEdition: { id: 3333 },
  extraEditionSubscriptionSaved: { id: 3446 },
  extraInfo: { id: 238 },
  extraProduct: { id: 41595 },
  extraProductDetails: { id: 43326 },
  extraProductFetchFailed: { id: 43517 },
  extraProductItem: { id: 625 },
  extraProductOrderCreated: { id: 43518 },
  extraProductStickerPrinted: { id: 3219 }, // Build 4.1.2.0 (ERP #12497)
  extraProducts: { id: 86 },
  existsReclaimWithReclPaper: { id: 10556 },
  existsReclaimWithSameDate: { id: 42275 },
  existsUnpaidInvoicesWithRemovingInvoiceMode: { id: 42396 }, // Build 3.6.4.1 (Issue #32668)
  existsSubsWithRemovingInvoiceMode: { id: 10560 }, // Build 3.6.4.1 (Issue #32668)
  failedCreatingExtraProductOrder: { id: 43519 },
  failedExtraProducts: { id: 43520 },
  failedReferencePayments: { id: 651 },
  failedSubs: { id: 43521 },
  failedToAddExtraProducts: { id: 43522 },
  failedToChangeTheLocaleTo: { id: 43523 },
  failedToCheckIfNewSubsCanBeCreated: { id: 43524 },
  failedToDetectIfSubsCanBeRemoved: { id: 10196 },
  failedToSendEmail: { id: 40583 },
  failedToValidateAddress: { id: 43525 },
  fax: { id: 3761 },
  feeAfterCancellation: { id: 3838 },
  feedback: { id: 323 },
  feedbacks: { id: 43449 },
  feedbackActions: { id: 832 },
  feedbackActionsHeader: { id: 3057 },
  feedbackCategoryRequired: { id: 10373 },
  feedbackChannel: { id: 3060 },
  feedbackDeleted: { id: 10259 },
  feedbackFetchFailed: { id: 43526 },
  feedbackHierarchy: { id: 3059 },
  feedbackNo: { id: 49 },
  feedbackNoCustomer: { id: 10705 },
  feedbackQuality: { id: 3061 },
  feedbackSavedSuccesfully: { id: 10474 },
  feedbackSaveFailed: { id: 10475 },
  feedbackSent: { id: 3089 },
  feedbackTaskForYou: { id: 3048 },
  feedbackTextRequired: { id: 10375 },
  feedbackType: { id: 264 },
  // feeGroup: { id: 432 },
  fetchingLedgerFailed: { id: 43527 },
  fetchingReport: { id: 43478 },
  field: { id: 4676 }, // Build 4.1.1.0 (ERP #11124)
  fileUpload: { id: 43477 },
  financial: { id: 1112 },
  findCarrier: { id: 32517 },
  findCustomer: { id: 652 },
  firstname: { id: 3459 },
  firstPaymentToBeRefunded: { id: 831 },
  fixCoDistributionState: { id: 41232 },
  fixedSupply: { id: 3772 },
  fixOfPayment: { id: 710 },
  floor: { id: 41616 }, // #27423, replace 31458
  focusChangeRemoved: { id: 43528 },
  focusCheckFailed: { id: 43529 },
  focusDataFetchFailed: { id: 43530 },
  focusingChangeStart: { id: 31488 },
  focusNo: { id: 43448 },
  focusUpdated: { id: 15381 },
  forbiddenCharactersAre: { id: 4752 },
  forenameSurname: { id: 231 },
  forepayment: { id: 631 },
  forepaymentCreated: { id: 43479 },
  forepaymentFailed: { id: 43480 },
  freeCopy: { id: 184 },
  freeCopyGroup: { id: 30211 },
  freetexts: { id: 3226 },
  freetext1: { id: 3250 },
  freetext2: { id: 3251 },
  freetext3: { id: 3252 },
  freetext4: { id: 3253 },
  friday: { id: 477 },
  fridayLong: { id: 811 },
  fromDate: { id: 3223 }, // Build 3.6.4.1 (Issue #33098)
  fromApartment: { id: 41212 },
  fromCash: { id: 713 },
  fromPostcode: { id: 30059 },
  fromStaircase: { id: 41210 },
  function: { id: 41934 },
  furtherInfoForInvoicing: { id: 3481 },
  gender: { id: 30444 },
  general: { id: 30321 },
  generalFeedback: { id: 43313 },
  generalInformation: { id: 3130 },
  giftcount: { id: 41951 },
  giftreason: { id: 41919 },
  giftSubscription: { id: 40249 },
  granter: { id: 461 },
  greeting: { id: 41613 },
  gross: { id: 3629 }, // Build 4.1.1.0 (ERP #11124
  grossExclVat: { id: 40861 },
  grossExclVatExceed: { id: 10015 }, // #31630
  grossInclVat: { id: 43322 }, // #29170
  group: { id: 5013 },
  handlingDate: { id: 703 },
  handlingStatus: { id: 10609 },
  modelrowheader: { id: 41912 }, // Build 3.6.4.2 (ERP #11828)
  hint: { id: 3065 },
  history: { id: 4303 },
  holidayAddress: { id: 3846 },
  holidayAddresses: { id: 760, fallback: "Holiday addresses" },
  holidayAddressCanNotBeSameAsBasic: { id: 42010 },
  holidayAddressUsedBySubs: { id: 10253 }, // Build 3.6.4.2 (ERP #11199)
  holidayStop: { id: 3847 },
  home: { id: 30963 },
  homePhone: { id: 4021 },
  houseNo: { id: 903 },
  houseNoStart: { id: 32085 },
  houseNoEnd: { id: 32086 },
  housingAssociation: { id: 3793 },
  housingCooperative: { id: 3799 },
  ibanAccount: { id: 3465 },
  ibanMightBeInvalid: { id: 43531 },
  ibanNumber: { id: 232 }, // Build 4.1.0.0 (ERP #10176)
  identification: { id: 1201 },
  ilPowerPlace: { id: 3760 },
  immediateInvoice: { id: 3154 },
  importance: { id: 31911 },
  inclvatamount: { id: 3633 }, // #30613, ledger grid field
  includeInvoiceFee: { id: 40296 }, // Build 3.6.4.1 (Issue #33098)
  includeReminderFee: { id: 43753 }, // Build 4.1.0.0 (ERP #11113)
  includeCancelFee: { id: 43754 }, // Build 4.1.0.0 (ERP #11113)
  inMonth: { id: 3467 },
  instalment: { id: 40689 },
  instalments: { id: 3634 },
  instalmentEnd: { id: 450 },
  instalmentStart: { id: 449 },
  instalmentsForProduct: { id: 43321 },
  instalmentCount: { id: 442 },
  instalmentCountSplitted: { id: 42929 },
  instalmentExpense: { id: 40375 },
  instalmentExpensePlusVat: { id: 43323 }, // #29170
  interest: { id: 3540 },
  internalTransfer: { id: 714 },
  internalTransferToCustomerOrSubs: { id: 716 },
  invalidAddressOrWrongAddressFormat: { id: 43532 },
  invalidCredentials: { id: 8 },
  invalidEmailAddress: { id: 911 }, // #27832
  invalidSalesno: { id: 43533 },
  invArg: { id: 43310 },
  invoice: { id: 722 },
  invoiceAddresses: { id: 3380, fallback: "Invoice addresses" },
  invoiceAddressCreated: { id: 43535 },
  invoiceAddressDeleted: { id: 43536 },
  invoiceAddressUpdated: { id: 43537 },
  invoiceDate: { id: 3530 },
  invoiceInfo: { id: 44332 }, // Build 4.1.1.0 (ERP #11684)
  invoiceInfo1: { id: 44333 }, // Build 4.1.1.0 (ERP #11684)
  invoiceInfo2: { id: 44334 }, // Build 4.1.1.0 (ERP #11684)
  invoiceInfo3: { id: 44335 }, // Build 4.1.1.0 (ERP #11684)
  invoicing: { id: 30512 },
  invoicingInterval: { id: 42711 },
  invoicingTradingName: { id: 43643 }, // Build 4.1.0.0 (ERP #10158)
  invoicingCountrySubDivision: { id: 43644 }, // Build 4.1.0.0 (ERP #10158)
  invoiceMode: { id: 233 },
  invoiceModeChanged: { id: 10367 },
  invoiceModeCreated: { id: 10365 },
  invoiceModeDeleted: { id: 10363 },
  invoiceNo: { id: 612 },
  invoicePeriodStartdate: { id: 234 },
  invoiceProduct: { id: 3157 },
  invoiceRows: { id: 3532 },
  invoiceStart: { id: 30103 },
  invoiceStatus: { id: 3680 },
  invoicesToTemporaryAddress: { id: 772 },
  invoiceType: { id: 614 },
  invoicingAddress: { id: 786 },
  invoicingCustomer: { id: 4247 },
  invoicingCustomerHasUnpaidSubs: { id: 40757 },
  invoicingData: { id: 3233 },
  invoicingFee: { id: 3632 },
  issues: { id: 43316 },
  issueQty: { id: 3503 },
  item: { id: 444 },
  itemCount: { id: 442 },
  itemEnds: { id: 450 },
  itemPriceWithVat: { id: 10846 },
  itemPriceWithoutVat: { id: 3809 },
  itemStarts: { id: 449 },
  kayak: { fallback: "Kayak" },
  labelDetails: { id: 43362 },
  language: { id: 3004 },
  lastname: { id: 3460 },
  lastCustomerSubsIsWaitingToBecomeActive: { id: 43538 },
  ledger: { id: 43312 },
  length: { id: 3654 },
  lengthExceedsMaxLength: { id: 43539 },
  letter: { id: 40265 },
  letterDeleted: { id: 43447 },
  letterDeleteFailed: { id: 43540 },
  letterSaved: { id: 40258 },
  letterSaveFailed: { id: 40257 },
  limitDate: { id: 31086 },
  loadingDiscountsFailed: { id: 43541 },
  loadingRefundFailed: { id: 43542 },
  locale: { id: 43446 },
  login: { id: 3 },
  logout: { id: 40101 },
  mail: { id: 31887 },
  mailBox: { id: 9999 },
  mailingMachine: { id: 499 },
  mainProduct: { id: 3158 },
  manners: { id: 43445 },
  marker: { id: 702 },
  marketingAllowancies: { id: 10734 },
  marketingChain: { id: 3763 },
  marketingMaterialAndCampaigns: { id: 10670 },
  masterCusno: { id: 30712 },
  maxLength35: { id: 43444 },
  mergedHidden: { id: 41114 },
  message: { id: 10532 },
  messageAddressAndTelephoneExists: { id: 40278 },
  messageCreateDuplicatedCustomer: { id: 843 },
  messageNameAndAddressExists: { id: 842 },
  messageNameAndTelephoneExists: { id: 40276 },
  messageSocialsecnoExists: { id: 40268 },
  missingEmail: { id: 4831 },
  modelid: { id: 41911 }, // Build 3.6.4.2 (ERP #11828)
  modelgroupname: { id: 41881 }, // Build 3.6.4.2 (ERP #11828)
  monday: { id: 473 },
  mondayLong: { id: 807 },
  months: { id: 3653 },
  monthlyPrice: { id: 32005 }, // Build 4.1.1.0 (ERP #11124)
  moreThanOneOpenInvoicesFoundCreditThemInLedger: { id: 3837 },
  moreThanOneOpenInvoicesFoundCreditThemInLedgerInvmode: { id: 42396 }, // #22383
  movedToCreditLosses: { id: 10695 },
  moveExtensionStartDateEarlier: { id: 40768 }, // Build 3.6.4.2 (Issue #33221)
  name: { id: 1 },
  name1: { id: 1, suffix: "1" },
  name1OverSize: { id: 44222 }, // Build 3.6.4.2 (ERP #11291)
  name2: { id: 3462 },
  name3: { id: 3463 },
  nameAndAddress: { id: 43299 },
  nameCriteriaAppliesTo: { id: 835 },
  nameLabel: { id: 518 },
  nameRows: { id: 515 },
  nameTypedOnThreeRows: { id: 3456 },
  needsToBeDone: { id: 3078 },
  negative: { id: 3062 },
  neutral: { id: 3063 },
  netPrice: { id: 706 },
  new: { id: 31263 }, // #27423
  newAddress: { id: 31311 },
  newAddressPending: { id: 43443 },
  newAllocationFrom: { id: 5140 },
  newBasicAddress: { id: 753 },
  newFeedback: { id: 85 },
  newcomm: { id: 435 },
  newCoDistribution: { id: 43442 },
  newCustomer: { id: 84 },
  newCustomerContact: { id: 10816 },
  newCustomerEvent: { id: 10821 },
  newHolidayAddress: { id: 762 },
  newHolidayAddressOverlaps: { id: 43543 },
  newHolidayAddressOverlapsWithOldAddress: { id: 43544 },
  newInvoicingAddress: { id: 787 },
  newPassword: { id: 849 },
  newPasswordAgain: { id: 30330 },
  newPasswordSameAsOldPassword: { id: 43441 },
  newPaper: { id: 43292 }, // #29170
  newPriceInclVat: { id: 43619 }, // Build 3.6.4.2 (Issue #33300)
  newProduct: { id: 43327 },
  newReport: { id: 31438, fallback: "..." },
  newSalesman: { id: 10291 },
  newSleep: { id: 43440 },
  newsPaperAgency: { id: 436 },
  newsPaperSpecificData: { id: 943 },
  newSubs: { id: 88 },
  newSubsCreated: { id: 40867 },
  newSubsCreatedButCustomerHasNoEmail: { id: 43545 },
  newSplittedAddress: { id: 782 },
  newsPaperDistrict: { id: 3766 },
  newTempAddressCreated: { id: 43546 },
  newValue: { id: 3589 },
  no: { id: 35 },
  noAddress: { id: 40367 },
  noCampaign: { id: 30890 }, // #29637
  noCampaignsAvailable: { id: 43547 },
  noCredit: { id: 40905 },
  noCrediting: { id: 3517 },
  noCustomerContacts: { id: 43548 },
  noCustomerEvents: { id: 43549 },
  noCustomerInformationForNumber: { id: 3103 },
  noDataToDisplay: { id: 43550 },
  noDeliveryChanges: { id: 43359 },
  noDiscountsAvailable: { id: 43551 },
  noDoorNumberExists: { id: 3576 },
  noEntries: { id: 43552 },
  noExtraProducts: { id: 43553 },
  noExtraProductSelected: { id: 43554 },
  noFeedbacks: { id: 43555 },
  noFocusing: { id: 3578 },
  noGroup: { id: 43345 }, // #29637
  noHandling: { id: 3098 },
  noInformation: { id: 43556 },
  noName: { id: 40721 }, // Build 3.6.4.2 (Issue #33117)
  noPaperSelected: { id: 43557 },
  noPendingOrStandingHolidayAddresses: { id: 43347 },
  noPendingOrStandingSplittedAddresses: { id: 43348 },
  noPriceGroupsAvailable: { id: 43676, fallback: "No pricegroups available" },
  noProducts: { id: 43438 },
  noProductsSelected: { id: 43679, fallback: "No products selected" },
  noProductPackages: { id: 43680, fallback: "No product packages" },
  noRecentHistory: { id: 43681, fallback: "No recent history" },
  noReverse: { id: 3458 },
  normal: { id: 153 },
  noRowsFound: { id: 43293 }, // #29170
  noRowsWithGivenSearchCriteria: { id: 330 },
  noSalesmanFound: { id: 4845 },
  noSleepsAvailable: { id: 43332 },
  noSubscriptions: { id: 43437 },
  noSubscriptionSelected: { id: 43682, fallback: "No subscription selected" },
  noSubscriptionsFound: { id: 41424 },
  noSubsKindSelected: { id: 43683, fallback: "No subscription kind selected" },
  notCredited: { id: 3514 },
  notDefined: { id: 108 },
  noTemplateFound: { id: 43684, fallback: "No template found for selected publisher" },
  notes: { id: 707 },
  notHandled: { id: 3118 },
  notRefunded: { id: 10655 },
  noticeSubsIsActivated: { id: 40708 }, // Build 3.6.4.2 (Issue #33221)
  numberOf: { id: 43436 },
  giftsSelected: { id: 43439 },
  office: { id: 605 },
  ok: { id: 40 },
  oldPassword: { id: 30328 },
  oldPriceInclVat: { id: 43618 }, // Build 3.6.4.2 (Issue #33300)
  oneOrMoreExtraProductsIsMissingRecommender: {
    id: 43685,
    fallback: "One or more extra products is missing recommender",
  },
  onlyInvoicesOfChosenSubs: { id: 3232 },
  onlyInvoicesOfNewestTransaction: { id: 43180 }, // Build 3.6.2.2 (Issue #31348)
  open: { id: 448 },
  openItems: { id: 43331 },
  openPlusAdditionalExpenses: { id: 43435 },
  openTemplate: { id: 30790 }, // Build 3.6.4.2 (ERP #11828)
  otherCusWithActiveSubsInAddress: { id: 10679 },
  otherCusWithUnpaidSubsInAddress: { id: 10680 },
  otherCrediting: { id: 3512 },
  otherIdentification: { id: 42193 }, // Issue #32668
  otherInfo: { id: 43320 }, // #29170
  oldCusno: { id: 40503 }, // Build 4.0.4.0 (ERP #10162)
  oldInvoiceNo: { id: 40502 }, // Build 4.0.4.0 (ERP #10162)
  oldValue: { id: 41813 },
  otherCusNo: { id: 243 },
  otherInformation: { id: 30479 },
  otherPhone: { id: 4023 },
  otherSubsNo: { id: 245 }, // Build 3.4.6.2 (Issue #33350)
  otherSubscriptionNumber: { id: 426 },
  otherTitle: { id: 41619 },
  orderAddressState: { id: 3359 },
  orderDate: { id: 40262 },
  orderId: { id: 276 },
  organizationNo: { id: 10801 },
  organizationNoMightBeInvalid: { id: 43188 },
  organizationTarget: { id: 43434 },
  originalClassification: { id: 10658 },
  originalDueDate: { id: 10385 },
  originalInvoiceDate: { id: 10384 },
  originalInvoiceForAlternativeInvoice: { id: 40558 },
  originalInvoiceType: { id: 10656 },
  originalPayment: { id: 40742 },
  originalPrintDate: { id: 10657 },
  originalTotalPrice: { id: 3566 },
  //ovtId: { id: 909 },     // Build 4.1.0.0 (ERP #10176)
  ownCustomerContacts: { id: 10819 },
  ownCustomers: { id: 19 }, // Build 4.0.4.0 (ERP #10162)
  ownCode: { id: 3773 },
  ownFeedbacks: { id: 3120 },
  package: { id: 3604 },
  packageId: { id: 3191 },
  paid: { id: 30418 },
  paidItems: { id: 43330 },
  paidFromUntil: { id: 3821 },
  paidItemsInvoiceDateRange: { id: 3569 },
  paidUntil: { id: 247 },
  paper: { id: 261 },
  paperCode: { id: 632 },
  paperDetails: { id: 43291 }, // #29170
  paperGroupDoesNotHaveAnyPapers: { id: 43558 },
  paperNotPublishedOnGivenDate: { id: 3111 },
  paperRedeliveryBefore: { id: 40646 },
  paperSpecificDiscounts: { id: 43308 },
  paperSpecificMarketingAllowancies: { id: 10685 },
  paperSpecificSubscriptionTypes: { id: 30899 },
  papers: { id: 40623 },
  parity: { id: 827 },
  partlyOrFullyPaid: { id: 42334 }, // #26219
  partlyPaid: { id: 41650 },
  passive: { id: 111 },
  password: { id: 2 },
  passwordAgain: { id: 3480 },
  passwordInfo: { id: 43559 },
  passwordIsRequired: { id: 10457 },
  passwordMinLengthWarning: { id: 43560 },
  passwordNotSameAsInOriginalField: { id: 10402 },
  passwordUpdated: { id: 15355 },
  passwordUpdateFailed: { id: 15356 },
  passwordResetRequestFailed: { id: 43561 },
  passwordResetRequestSent: { id: 43562 },
  payDate: { id: 604 },
  payer: { id: 249 },
  payerCusName: { id: 3606 },
  payerCusno: { id: 10201 },
  payerCustomerDiscountCouldNotBeBrought: { id: 10342 },
  payment: { id: 99 },
  paymentExtraInfo: { id: 43971 },
  paymentHasBeenSaved: { id: 10486 },
  paymentItemsMax: { id: 3661 },
  paymentOnInvoice: { id: 611 },
  paymentOnSubs: { id: 621 },
  paymentRevenue: { id: 43972 },
  paymentRevenueDate: { id: 43974 },
  paymentRevenueNet: { id: 43975 },
  paymentRevenueRevert: { id: 43973 },
  paymentRevenueVat: { id: 43976 },
  paymentsAreEven: { id: 10676 },
  paymentSaveFailed: { id: 10487 },
  paymentsLoadFailed: { id: 43563 },
  paymentTerms: { id: 283 },
  pcs: { id: 3238 },
  pending: { id: 43433 },
  pendingAddressChangeDeleted: { id: 43564 },
  pendingChanges: { id: 3814 },
  period: { id: 32181 }, // Build 3.6.4.2 (ERP #11118)
  periodic: { id: 176 },
  periodicSubscription: { id: 3656 },
  periodNumber: { id: 3581 },
  person: { id: 135 },
  phone: { id: 28 },
  phoneNrWork: { id: 3032 },
  phoneOther: { id: 3033 },
  pleaseEnterDate: { id: 934 },
  pleaseTryToSaveFailedExtraProductsAgain: { id: 43565 },
  pool: { id: 3590 },
  positive: { id: 3064 },
  post: { id: 31887 },
  postalCode: { id: 3342 },
  postalFee: { id: 44188 }, // ERP #11139
  postCode: { id: 26 },
  postCodes: { id: 30087 },
  posting: { id: 32625 },
  postName: { id: 40447 },
  postOffice: { id: 503 },
  countryCode: { id: 44386 },
  postOfficeDeleted: { id: 43566 },
  postExpCode: { id: 759 }, // #29636, postexpcode field in focus grid
  potential: { id: 109 },
  preparingNewSubscription: { id: 43567 },
  previous: { id: 43358 },
  previousAgent: { id: 3757 },
  price: { id: 10228 },
  priceAccordingToSubsLength: { id: 3666 },
  priceAmountExceed: { id: 10016 }, // #31630
  priceArea: { id: 3658 },
  priceExclVat: { id: 3811 },
  priceGuarantee: { id: 451 },
  priceGuaranteeForPeriod: { id: 10298 },
  priceGroup: { id: 253 },
  priceInclVat: { id: 3815 },
  priceList: { id: 3668 },
  priceInPeriods: { id: 3665 },
  priceNotSameAsPriceList: { id: 3727 }, // #33445
  primaryAddress: { id: 3360 },
  prizeMaintenance: { id: 40753 },
  prizeName: { id: 40751 },
  pricePerDay: { id: 3662 },
  priceToBeInvoiced: { id: 3751 },
  priceWizard: { id: 3657 },
  print: { id: 3041 },
  printed: { id: 3088 },
  printedDate: { id: 3096 },
  printingDate: { id: 254 },
  printInvoice: { id: 102 },
  privateCustomer: { id: 3798 },
  processed: { id: 31117 },
  product: { id: 59 },
  productAddedToSubscription: { id: 43568 },
  productid: { id: 4062 },
  productInformation: { id: 43432 },
  productName: { id: 41926 },
  productRemovedFromSubscription: { id: 43569 },
  productCode: { id: 58 },
  productChange: { id: 3828 }, // Build 3.6.4.2 (ERP #10880)
  productChangeAfterItemNo: { id: 3826 }, // Build 3.6.4.2 (ERP #10880)
  productChangeAfterDate: { id: 3827 }, // Build 3.6.4.2 (ERP #10880)
  productChangeCanNotChangeBecauseSubState: { id: 10625 }, // Build 3.6.4.2 (ERP #10880)
  productChangeExistsExtensExtension: { id: 10124 }, // Build 3.6.4.2 (ERP #10880)
  productChangeTooEarlierInvStartDate: { id: 10633 }, // Build 3.6.4.2 (ERP #10880)
  products: { id: 30137 },
  productPackage: { id: 3347 },
  productPackageWasSavedButOneOrMoreExtraProductsFailed: { id: 43570 },
  profession: { id: 433 },
  profile: { id: 10450 },
  profileInfo: { id: 43300 },
  protectedIdentity: { id: 40271 },
  protectedIdentityWarning: { id: 40281 }, //Build 4.0.4.0 (ERP #10145)
  provideStartingDateForNewPayer: { id: 43482 }, // #29633
  publication: { id: 52 },
  publisherChanged: { id: 43572 },
  publisherFetchFailed: { id: 43573 },
  publishingCalendar: { id: 30167 },
  publishingDate: { id: 51 },
  quantities: { id: 471 },
  quantity: { id: 10600 },
  quickSearch: { id: 846 }, // Build 3.4.6.2 (Issue #33350)
  reason: { id: 10684 },
  receipt: { id: 607 },
  receiptRequested: { id: 43431 },
  receiveChannel: { id: 273 },
  receiverCusno: { id: 3236 },
  receiverExtno: { id: 3734 },
  receiverInvno: { id: 3735 },
  receiverName: { id: 3732 },
  receiverOfExtraProduct: { id: 3227 },
  receiverSubsno: { id: 3733 },
  recentCustomers: { id: 931 },
  reclamationCopy: { id: 32053 },
  reference: { id: 31252 },
  referenceNo: { id: 613 },
  referencePayments: { id: 641 },
  refund: { id: 101 },
  refunded: { id: 10654 },
  refundMethod: { id: 709 },
  refundReason: { id: 701 },
  refundResend: { id: 41184 },
  refundSaved: { id: 43574 },
  refundTooMuch: { id: 3562 }, // #31655
  refundType: { id: 717 },
  refundTransferNeedCusnoSubsnoInvno: { id: 10669 }, // Build 3.6.4.2 (Issue #33541)
  refundTransferToCreditedInvoiceNotAllowed: { id: 42494 }, // Build 3.6.4.2 (Issue #33541)
  refundTransferPayerNotMatchSubscription: { id: 10843 }, // Build 3.6.4.2 (Issue #33541)
  refundTransferPayerNotMatchInvoice: { id: 10844 }, // Build 3.6.4.2 (Issue #33541)
  refundTransferInvoiceNotMatchSubscritpion: { id: 10845 }, // Build 3.6.4.2 (Issue #33541)
  remainingMoney: { id: 3679 },
  remarks: { id: 4084 },
  reminder: { id: 41651 },
  removeAddress: { id: 3687 }, // Build 3.6.4.2 (ERP #11199)
  removeCancel: { id: 3836 },
  removeFile: { id: 40577 }, // Build 3.6.4.2 (ERP #11828)
  removeWaitingFocusingChange: { id: 10275 },
  removingCancelFailed: { id: 43575 },
  removingDynamicProductFailed: { id: 44359 }, // Build 4.1.1.0 (ERP #11124)
  removingSubscription: { id: 43334 },
  replace: { id: 96 },
  replyLatest: { id: 42202 },
  // Issue #28270 v
  reports: { id: 3125 },
  repGroup02: { id: 13, fallback: "Customer service" },
  repGroup03: { id: 1112, fallback: "Invoicing" },
  repGroup04: { id: 1113, fallback: "Sales and Marketing" },
  repGroup05: { id: 1114, fallback: "Post" },
  repGroup08: { id: 1115, fallback: "Distribution" },
  repGroup09: { id: 1116, fallback: "Salesmaterial" },
  repGroupUndef: { id: 1117, fallback: "Undefined" },
  // Issue #28270 ^
  reportLoadingFailed: { id: 43576 },
  responsible: { id: 43354 },
  responsibleOrganization: { id: 3075 }, // Build 3.6.4.2 (Erp #10971)
  responsiblePerson: { id: 3076 },
  receiver: { id: 3235 },
  receiveType: { id: 273 },
  recipients: { id: 43430 },
  recommender: { id: 421 },
  recommenderName: { id: 30765 },
  redeliveryServiceLevel: { id: 10280 },
  removalFailed: { id: 40112 },
  remove: { id: 3694 },
  removeCustomerConfirm: { id: 924 },
  removeCustomerOrHideConfirm: { id: 43061 }, // Build 3.6.2.2 (Issue #31348)
  removeCustomerOnlyHideConfirm: { id: 43305 }, // ERP #10310
  removeExpenses: { id: 40465 },
  removeExpensesConfirm: { id: 40464 }, // Build 3.6.4.1 (Issue #33098)
  removeFutureEvent: { id: 3600 },
  removeFeedback: { id: 10711 },
  removePrintingNote: { id: 3822 },
  removePrintingNoteQuestion: { id: 10512 }, // #30613, remove printingnote question text
  removeSleep: { id: 43429 },
  removeTransfer: { id: 3823 },
  removingCustomerIsNotAllowed: { id: 344 },
  removingInvoiceExpensesFailed: { id: 43577 },
  removingPrintingNoteFailed: { id: 43578 },
  removingPrintingNoteSucceeded: { id: 43579 },
  removingSubscriptionFailed: { id: 40885 },
  removingTransferMarkFailed: { id: 43580 },
  renewal: { id: 89 },
  renewalCreated: { id: 43581 },
  renewalExists: { id: 10642 },
  renewed: { id: 30834 },
  repayment: { id: 31237 },
  resellerType: { id: 847 },
  reserveEventChanged: { id: 43582 },
  reserveEventChangeFailed: { id: 43583 },
  reserveEventDeleted: { id: 43584 },
  reserveEventDeleteFailed: { id: 43585 },
  restarted: { id: 31442 },
  resendEmail: { id: 43586 },
  resetPassword: { id: 43427 },
  results: { id: 40270 },
  requestPasswordReset: { id: 43428 },
  required: { id: 43314 },
  retainRightToReadWebEdition: { id: 3504 },
  return: { id: 41478 },
  returnExpenses: { id: 40371 },
  returnExpensesConfirm: { id: 40374 }, // Build 3.6.4.1 (Issue #33098)
  returningInvoiceExpensesFailed: { id: 43426 },
  returnProhibition: { id: 3770 },
  roundAllocation: { id: 30568 },
  route: { id: 3775 },
  reverse: { id: 3457 },
  row: { id: 708 },
  rows2: { id: 798 },
  rows3: { id: 797 },
  revertRevenueFailed: { id: 43978 }, // Build 4.1.0.0 (ERP #11136)
  sales: { id: 5030 },
  salesman: { id: 268 },
  salesmanDeleted: { id: 43352 },
  salesmanDetails: { id: 43349 },
  salesmanGroup: { id: 504 },
  salesmanGroupNumber: { id: 43319 },
  salesmanId: { id: 4070 },
  salesmanNumber: { id: 514 },
  salesmanSaved: { id: 43351 },
  salesmanExists: { id: 10299 }, // Build 3.4.6.2 (Issue #33446)
  salesNo: { id: 10205 },
  salesDate: { id: 266 },
  salesGroup: { id: 504 },
  salesPersonGroups: { id: 439 },
  salesSecretary: { id: 3765 },
  sameAddressAlreadyHasActiveSubs: { id: 10675 },
  sameAddressHasCustomersWithUnpaidSubs: { id: 10680 },
  sameAddressHasFreeOrCheapSubs: { id: 41774 },
  sameFocusno: { id: 1210 }, // Build 3.6.4.2 (Issue #33117)
  sample: { id: 177 },
  saturday: { id: 478 },
  saturdayLong: { id: 812 },
  save: { id: 40099 },
  saveChanges: { id: 43174 }, // #27423
  saveCustomerProfile: { id: 43425 },
  savedExtraProducts: { id: 43424 },
  saveFocusing: { id: 3817 },
  saveInvoicingChanges: { id: 43175 }, // #27423
  saveNew: { id: 30631 },
  saveOnly: { id: 41932 },
  saveOrAbortChanges: { id: 43149 },
  saveOtherInformation: { id: 43078 },
  saveProfileChanges: { id: 43077 },
  savingAddressLabelFailed: { id: 43076 },
  savingContactInfoFailed: { id: 43075 },
  savingCustomerFailed: { id: 43148 }, // Same for new and update
  savingCustomerSucceeded: { id: 40866 },
  savingDoornotesFailed: { id: 43074 },
  savingHolidayAddressFailed: { id: 10443 },
  savingHolidayAddressToCertainSubsFailed: { id: 10443 },
  savingPaperDiscountFailed: { id: 43058 },
  savingPaperSubsTypeFailed: { id: 43423 },
  savingPostOfficeFailed: { id: 10787 },
  savingSalesmanFailed: { id: 43057 },
  savingSalesmanPaperFailed: { id: 43056 },
  savingSalesmanPrizeFailed: { id: 43055 },
  savingSalesPersonGroupFailed: { id: 43054 },
  savingSingleSubsCustomerFailed: { id: 43053 },
  savingTotalMarketingDenialFailed: { id: 43052 },
  search: { id: 3705 },
  searchLabelText: { id: 10345 }, // Build 3.4.6.2 (Issue #33350)
  searchAddress: { id: 900 },
  searchAgain: { id: 858 },
  searchBy: { id: 43051 },
  searchCarrier: { id: 32517 },
  searchCoDistribution: { id: 43050 },
  searchCount: { id: 940 },
  searchCriteria: { id: 3595 },
  searchCustomer: { id: 907 },
  searchHousingCooperative: { id: 43049 },
  searchInvoicingCustomer: { id: 907 },
  searchPayer: { id: 502 },
  searchRecommender: { id: 501 },
  searchSalesman: { id: 500 },
  searchSubscriberCustomer: { id: 907 },
  seasonPlace: { id: 3768 },
  selectDifferentSubscription: { id: 41591 },
  selectExistingAddress: { id: 10682 },
  select: { id: 507 },
  selectAddress: { id: 10682 },
  selectCountry: { id: 10770 },
  selectedSubsUpdatedWithInvMode: { id: 43048 },
  selectInvoicedExtraChanges: { id: 10763 },
  selectInvoicedSubscriptionPeriods: { id: 10762 },
  selectFeedbackHierarchy: { id: 10274 },
  selectGifts: { id: 41953 },
  selectedGifts: { id: 42073 },
  selectGiftsToSubscription: { id: 41953 },
  selectOrAddPostOffice: { id: 10782 },
  selectPostoffice: { id: 10781 },
  selectPaper: { id: 31384 }, // Build 3.6.4.2 (Issue #33541)
  selectRefundMethod: { id: 43047 },
  selectWeek: { id: 10828 },
  sleepStartDate: { id: 3501 }, // Build 3.6.4.2 (Issue #33117)
  sleepStartDateSholdNotBeforeIssueDate: { id: 42433 }, // Build 3.6.4.2 (ERP #11230)
  sleepEndDate: { id: 3502 }, // Build 3.6.4.2 (Issue #33117)
  sleepEndDateLongerThanSubsEndDateConfirmToRenew: { id: 40003 },
  sleepEndDateLongerThanSubsEndDateNeedToRenewFirst: { id: 10835 },
  sleepOtherCreditTypeNotMatchSubsPeriod: { id: 40733 },
  sleepPeriodNotMatchSubsPeriod: { id: 40734 },
  sleepAlreadyStart: { id: 40883 }, // Build 3.6.4.2 (Issue #33117)
  seller: { id: 268 },
  sellerGroup: { id: 31964 },
  sellerGroupNumber: { id: 504 },
  send: { id: 40214 }, // #28738, add send invoice button
  sendAgain: { id: 10651 }, // #27311, button text: send autogiro again
  sendEmail: { id: 41407 },
  sendEmailCusContact: { id: 40580 },
  sendInvoice: { id: 4857 }, // #28738, add sendautogiro checkbox
  SendPrintedLetterCusContact: { id: 41914 }, // Build 3.6.4.2 (ERP #11828)
  sendSMSCusContact: { id: 40658 },
  sender: { id: 40578 },
  senderEmailAddressInvalid: { id: 40581 }, // Build 3.6.4.2 (ERP #11828)
  separateInvoice: { id: 3218 },
  separateWithComma: { id: 42153 },
  serviceIsProvidedToAddresses: { id: 3805 },
  serviceLevel: { id: 32155 },
  setAtLeastOneCopy: { id: 43046 },
  settings: { id: 3436 },
  settingsCleared: { id: 43045 },
  shouldAdditionalExpensesBeSplitted: { id: 10815 },
  shouldAddAddressChangeFee: { id: 40167 }, // Build 3.6.4.2 (Issue #33315)
  shouldRemoveAddressChangeFeeManually: { id: 40217 }, // Build 3.6.4.2 (Issue #33315)
  shouldCreditInvoice: { id: 3752 }, // Build 3.6.4.2 (Issue #33300)
  showFocus: { id: 3377 },
  showPricelist: { id: 3625 },
  singleCopies: { id: 824 },
  singleCopy: { id: 182 },
  singleCopyCantChange: { id: 41059 }, // #18896
  singleSubsCustomer: { id: 3754 },
  sleep: { id: 93 },
  sleepLimitInfo: { id: 43593 },
  sleepRemoved: { id: 10838 },
  sleeps: { id: 43073 },
  sleepSubsHaveOpenInvoices: { id: 41496 }, // Build 3.6.4.2 (Issue #12086)
  sleepSubsUnpaidBreakDate: { id: 43215 }, // Build 4.0.4.0 (ERP #10173)
  sms: { id: 242 },
  socialSecurityCode: { id: 3607 },
  socialSecNo: { id: 270 },
  socialSecnoMightBeInvalid: { id: 15336 },
  socialSecNoInvalid: { id: 913 }, // Build 3.6.4.2 (ERP #11080)
  socialSecNoMissing: { id: 31356 }, // Build 3.6.4.2 (ERP #11080)
  source: { id: 21 },
  sortOrder: { id: 3682 },
  specialnumber1: { fallback: "Special number 1" }, // Not used anywhere?
  specifier: { id: 10704 },
  splitAdditionalExpenses: { id: 43147 },
  splitAddressCreated: { id: 43020 },
  splitAddressDeleted: { id: 43021 },
  splitAddressUpdated: { id: 43022 },
  splittedAddress: { id: 780 },
  splittedAddresses: { id: 780 },
  splittedAddressInfo: { id: 3379 },
  stack: { id: 804 },
  stackName: { id: 805 },
  staircase: { id: 41615 }, // #27423, use 41615 to replace 904, keep the same as CS
  stair: { id: 788 }, // Build 3.6.4.2 (Issue #33117)
  standing: { id: 31984 },
  standingSubscription: { id: 3655 },
  startBeforeEnd: { id: 43019 },
  startByAddingPaperOrExtraProduct: { id: 43422 },
  startDate: { id: 3223 },
  startDateCannotBeLaterThanEndDate: { id: 3722 },
  starting: { id: 754 },
  stopCancellingSubscription: { id: 43146 },
  stopDateChange: { id: 5139 },
  stopDateUpdated: { id: 10485 },
  stopDateUpdateFailed: { id: 10484 },
  stopDeliveryChange: { id: 838 },
  stopDeliveryChangeForTempStart: { id: 41131 }, // Build 3.6.3.4 (Issue #32668)
  stopDeliveryChangeForTempStop: { id: 41132 }, // Build 3.6.3.4 (Issue #32668)
  stopType: { id: 3510 },
  street: { id: 4010 },
  street2: { id: 4044 },
  street3: { id: 4045 },
  streetAddressIsRequired: { id: 10437 },
  streetName: { id: 768 }, // Build 3.6.4.2 (Issue #33117)
  streetName1: { id: 902 }, // Build 3.4.6.2 (Issue #33350)
  streetSegment: { id: 790 },
  subject: { id: 40213 },
  submittedSubs: { id: 43018 },
  subsCannotBeFound: { id: 10251 },
  subsCannotBeRemovedBecauseItHasBeenCancelled: { id: 40572 },
  subsCannotBeRemovedBecauseItHasBeenInvoiced: { id: 40869 },
  subsCannotBeRemovedBecauseItHasPayment: { id: 40571 },
  subsCreatedButUpdatingItemsFailed: { id: 43017 },
  subscriber: { id: 3229 },
  subscriberName: { id: 3605 },
  subscription: { id: 721 },
  subscriptionAddressReadingFailed: { id: 10144 }, // Build 3.6.3.4 (Issue #32668)
  subscriptions: { id: 3745 },
  subscriptionCanNotFoundFromDistribution: { id: 43107 }, // #33092
  subscriptionCancel: { id: 95 },
  subscriptionCancelFailed: { id: 10361 },
  subscriptionChange: { id: 90 },
  subscriptionConfirmation: { id: 212 },
  subscriptionCustomer: { id: 10257 },
  subscriptionDiscountType: { id: 43016 },
  subscriptionEnds: { id: 3565 },
  subscriptionFeedback: { id: 316 },
  subscriptionFetchFailed: { id: 43015 },
  subscriptionGifts: { id: 42073 },
  subscriptionHasAlreadyBeenCancelled: { id: 3563 },
  subscriptionHasBeenCancelled: { id: 3554 },
  subscriptionHistory: { id: 43014 },
  subscriptionId: { id: 276 },
  subscriptionInformation: { id: 76 },
  subscriptionIsNotActiveAtGivenDate: { id: 3110 },
  subscriptionKind: { id: 10007 },
  subscriptionLength: { id: 278 },
  subscriptionNumber: { id: 275 },
  subscriptionPeriod: { id: 3706 },
  subscriptionProducts: { id: 30137 },
  subscriptionQty: { id: 419 },
  subscriptionRemove: { id: 335 },
  subscriptionRemoved: { id: 3684 },
  subscriptionReserve: { id: 43344 }, // #29630
  subscriptionSaveFailedFields: { id: 10000 }, // #31630
  subscriptionSelling: { id: 241 },
  subscriptionSpecificInvAddress: { id: 10715 },
  subscriptionSpecificInvText: { id: 10741 },
  subscriptionState: { id: 279 },
  subscriptionStateUpdated: { id: 43013 },
  subscriptionStateUpdateFailed: { id: 43012 },
  subscriptionStarts: { id: 3564 },
  subscriptionSuspend: { id: 93 },
  subscriptionType: { id: 280 },
  subscriptionWillHaveDiscount: { id: 10713 },
  subsEnddate: { id: 3565 },
  subsExtNo: { id: 3095 },
  subsHasNoInstalmentsSet: { id: 43145 },
  subsHasNoProductsYet: { id: 43011 },
  subsPeriod: { id: 272 },
  subsStartdate: { id: 3564 },
  subsStarts: { id: 61 },
  subsTypeDiscountCouldNotBeBroughtToSubs: { id: 10341 },
  successfullySavedCustomerData: { id: 3401 },
  successfullySavedSubscriptions: { id: 40867 },
  successiveMonths: { id: 42709 },
  sum: { id: 446 },
  sumExclVat: { id: 40740 },
  summary: { id: 31114 },
  summaryView: { id: 3490 },
  sunday: { id: 479 },
  sundayLong: { id: 813 },
  supplier: { id: 41624 },
  education: { id: 44199 }, // Build 4.1.0.0 (ERP #11252)
  internal: { id: 44213 }, // Build 4.1.0.0 (ERP #11252)
  supplyLocation: { id: 3774 },
  suspendDate: { id: 284 },
  suspended: { id: 188 },
  suspendUntilFurtherNotice: { id: 3523 },
  suspensionDetails: { id: 3847 },
  suspensionEnds: { id: 31480 },
  suspensionPeriod: { id: 10697 },
  suspensionReason: { id: 42001 },
  suspensionStarts: { id: 31479 },
  swiftCode: { id: 719 },
  swiftBicCode: { id: 40783 }, // Build 4.1.0.0 (ERP #10176)
  targetGroup: { id: 282 },
  targetGroupMustSelect: { id: 42488 }, // Build 3.6.4.1 (Issue #32668)
  tax: { id: 43872 }, // Build 4.1.1.0 (ERP #11124)
  taxAmount: { id: 40741 },
  taxationForm: { id: 465 },
  taxcomm: { id: 434 },
  taxValue: { id: 40752 },
  tempAddressUpdated: { id: 10418 },
  tempAddressRemoved: { id: 10420 },
  tempname1: { id: 40607, suffix: "1" },
  tempname2: { id: 40607, suffix: "2" },
  temporaryAddress: { id: 770 },
  temporaryNameChange: { id: 764 },
  textmode: { id: 43152 }, // #27619
  thisWeek: { id: 10829 },
  thursday: { id: 476 },
  thursdayLong: { id: 810 },
  timePeriod: { id: 3366 },
  title: { id: 30337 },
  toApartment: { id: 41213 },
  toBeCredited: { id: 10500 }, // #31655
  toBeInvoiced: { id: 40685 },
  toBeRefunded: { id: 3568 },
  toDate: { id: 3224 }, // Build 3.6.4.1 (Issue #33098)
  today: { id: 32220 },
  toPostcode: { id: 30060 },
  toStaircase: { id: 41211 },
  total: { id: 41565 },
  totalMarketingDenial: { id: 10683 },
  totalPrice: { id: 3337 },
  townCity: { id: 906 },
  transaction: { id: 3488 },
  transferred: { id: 3820 },
  transport: { id: 3376 },
  transportCode: { id: 3341 },
  transportOrganization: { id: 30543 },
  tuesday: { id: 474 },
  tuesdayLong: { id: 808 },
  type: { id: 40977 },
  unaddressed: { id: 822 },
  undefinedError: { id: 32591 },
  unallocated: { id: 3381 },
  unauthorized: { id: 43010 },
  uncheckAll: { id: 41170 },
  uninvoiced: { id: 860 },
  uninvoicedInvexpitem: { id: 43783 }, // Build 3.6.4.2 (ERP #11122)
  unit: { id: 462 },
  unitPrice: { id: 3221 },
  unpaidCancelled: { id: 41652 },
  unpaidEnded: { id: 331 },
  unpaidInvoiced: { id: 190 },
  unpaidItemsInvoiceDateRange: { id: 3570 },
  unsuccessfullySavedCustomerData: { id: 10116 },
  unsuccessfullySavedSubscriptions: { id: 10109 },
  unsupportedLanguage: { id: 43008 },
  unsupportedLocale: { id: 43009 },
  until: { id: 30359 },
  untilFurtherNotice: { id: 828 },
  update: { id: 650 },
  updateAllActiveSubsWithThisAddress: { id: 43007 },
  updated: { id: 10510 },
  updateCustomer: { id: 82 },
  updateCustomerContact: { id: 10817 },
  updateCustomerEvent: { id: 10822 },
  updateFeedback: { id: 10710 },
  updateSalesman: { id: 144 },
  updateSubs: { id: 90 },
  updateSubsInvMode: { id: 43142 },
  updatingAdvPaymentStateFailed: { id: 43006 },
  updatingBasicAddressFailed: { id: 43004 },
  updatingBasicAddressSucceeded: { id: 43005 },
  updatingDynamicProductDetailsFailed: { id: 44352 }, // Build 4.1.1.0 (ERP #11124)
  updatingInvoiceAddressFailed: { id: 10718 },
  updatingMarketingAllowanceFailed: { id: 10687 },
  updatingNotesFailed: { id: 43003 },
  updatingSplittedAddressFailed: { id: 43002 },
  updatingSubsFailed: { id: 10254 },
  updatingSubsWithInvModeFailed: { id: 43001 },
  updatingTempAddressFailed: { id: 10419 },
  usedAdvancePayment: { id: 41500 },
  user: { id: 201 },
  userId: { id: 40859 },
  userEventReadingFailed: { id: 10125 }, // Build 3.6.3.4 (Issue #32668)
  userEventSavingFailed: { id: 10126 }, // Build 3.6.3.4 (Issue #32668)
  userEventCheckCancelReasonFailed: { id: 10127 }, // Build 3.6.3.4 (Issue #32668)
  userEventStoreCancelReasonFailed: { id: 10128 }, // Build 3.6.3.4 (Issue #32668)
  usernameIsRequired: { id: 10456 },
  userSettingsInvalid: { id: 43000 },
  useSavedTemplate: { id: 40286 },
  value: { id: 4753 },
  valid: { id: 41768 },
  validateAddress: { id: 795 },
  validityPeriod: { id: 3659 },
  validFrom: { id: 41747 },
  validFromBeforeValidTo: { id: 42999 },
  validTo: { id: 41748 },
  vat: { id: 447 },
  vatNumber: { id: 41720 },
  vatPercent: { id: 3813 },
  vatRegistered: { id: 437 },
  version: { id: 10283 }, // #29638, add version
  viaPost: { id: 712 },
  visibleColumnRequired: { id: 42998 },
  visibilityOfColumns: { id: 3672 },
  vilandeMedgivande: { id: 44266 }, // Build 3.6.4.2 (ERP #11653)
  waiting: { id: 42997 },
  waitingAndValidPeriods: { id: 10304 },
  waitingBasicAddress: { id: 43183 }, // Build 3.6.4.2 (ERP #11199)
  waitingToActivate: { id: 186 },
  warning: { id: 10533 },
  warningDeletedCustomer: { id: 41645 }, // #30631
  webaddress: { id: 40504 },
  wednesday: { id: 475 },
  wednesdayLong: { id: 809 },
  week: { id: 10596 },
  weekday: { id: 806 },
  weekDayQtys: { id: 10281 },
  windowOpenFailed: { id: 43143 },
  windowOpenSuccess: { id: 43144 },
  winsaleType: { id: 41550 }, // Build 3.6.4.2 (Issue #26462)
  wireTransfer: { id: 711 },
  withoutVat: { id: 3553 },
  workPhone: { id: 4022 },
  wouldYouLikeToMarkTotalMarketingDenial: { id: 41177 },
  WouldYouLikeKeepPaycusno: { id: 938 }, // Build 3.6.4.2 (Issue #33388)
  wrongOldPassword: { id: 15354 },
  wwwUserId: { id: 3478 }, // Issue #32668
  wwwUsersId: { id: 10621 }, // Build 4.0.4.0 (ERP #10162)
  wwwPinCodeNew: { id: 3479 }, // Issue #32668
  wwwPinCodeAgain: { id: 3480 }, // Issue #32668
  wwwUserIdMustDiffWithPinCode: { id: 10177 }, // Issue #32668
  wwwPinCodeTooShort: { id: 10178 }, // Issue #32668
  wwwPinCodeContainsInvalidChar: { id: 10179 }, // Issue #32668
  wwwPinCodeContainsSpace: { id: 10180 }, // Issue #32668
  wwwPinCodeDiffWithSecond: { id: 3397 }, // Issue #32668
  yes: { id: 34 },
  zipcode: { id: 4017 },
  zipCodeIsRequiredForAddressTwoLines: { id: 3392 }, // #33092
  nonRefundablePeriod: { id: 40282, fallback: "Non refundable period" },

  // Issue #27386  Report page
  counter: { fallback: "Counter" },
  repno: { id: 31490 },
  report: { id: 30559 },
  readyReports: { id: 31892 },
  repname: { id: 30539 },
  repgroupno: { fallback: "Repgroupno" },
  searchReports: { fallback: "Search Reports" },
  fileno: { id: 32103, fallback: "Fileno" },
  parameter1: { id: 31150, fallback: "Parameter1" },
  parameter2: { id: 31151, fallback: "Parameter2" },
  parameter3: { id: 31152, fallback: "Parameter3" },
  noReportsFound: { fallback: "No Reports Found" },
  noRepRequestsFound: { fallback: "No RepRequests Found" },
  reprequests: { fallback: "Reprequests" },
  ftp: { id: 31140, fallback: "Ftp" },
  directory: { id: 30313, fallback: "Directory" },
  id: { id: 30064, fallback: "ID" },
  request: { id: 31153, fallback: "Request" },
  next: { id: 31154, fallback: "Next" },
  file: { id: 4684, fallback: "File" },
  shift: { id: 31155, fallback: "Shift" },
  key: { id: 31156, fallback: "Key" },
  alias: { id: 41098, fallback: "Alias" },
  mode: { id: 41098, fallback: "Mode" },
  runstart: { id: 40712, fallback: "Run start" },
  runend: { id: 40713, fallback: "Run end" },
  run: { id: 31438, fallback: "Run" },
  processLog: { fallback: "Process log" },
  state: { fallback: "State" },
  paramkey: { fallback: "ParamKey" },
  runby: { fallback: "Run by" },
  reportRequest: { id: 31394, fallback: "Report Requests" },
  show: { id: 5001, fallback: "Show" }, // Issue #33168, uniform fallback language?
  allusers: { fallback: "All Users" },
  currtentUser: { fallback: "Current User" },
  dateRange: { id: 5040, fallback: "Date Range" },
  listFileNumber: { id: 5010, fallback: "List file number" },
  running: { id: 31004, fallback: "Runing" },
  ready: { id: 47, fallback: "Ready" },
  typeOfApplcation: { id: 5039, fallback: "Type of Application" },
  customerService: { id: 13, fallback: "Customer Service" },
  monetaryList: { id: 5019, fallback: "Monetary Lists" },
  marketing: { id: 1113, fallback: "Marketing" },
  failed: { fallback: "Failed" },
  load: { id: 40094, fallback: "load" },
  finished: { id: 31972, fallback: "Finished" },
  pause: { id: 40606, fallback: "Pause" },
  productChange: { id: 43904, fallback: "Change product" },
  productChangesOn: { id: 43905, fallback: "Change begins" },
  preview: { id: 30794, fallback: "Preview" },
  orderPrice: { id: 10228, fallback: "Order price" },
  toBePaid: { id: 448, fallback: "Open amount" },
  genericFetchError: { fallback: "Fetch failed" },

  /** #33168 add users admin */
  admin: { id: 43929, fallback: "Admin" },
  userManagement: { id: 43930, fallback: "User management" },
  manageUsersAndGroups: { id: 43931, fallback: "Manage users, user groups & group rights" },
  users: { id: 43932, fallback: "Users" },
  groups: { id: 43933, fallback: "Groups" },
  groupRights: { id: 43934, fallback: "Groups rights" },
  // userGroups: { fallback: "User groups" },
  username: { id: 43938, fallback: "Username" },
  groupId: { id: 43647, fallback: "Group Id" },
  fullName: { id: 1, fallback: "Full name" },
  userMark: { id: 31350, fallback: "Mark" },
  userOrganization: { id: 41876, fallback: "Organization" },
  newUser: { id: 43936, fallback: "New user" },
  editUser: { fallback: "Edit user" },
  newGroup: { id: 43935, fallback: "New group" },
  editGroup: { fallback: "Edit group" },
  noData: { id: 43937, fallback: "No data" },
  removingUser: { id: 43940, fallback: "Remove user" },
  removingGroup: { id: 43941, fallback: "Remove user group" },
  doYouWantToRemoveUser: { id: 43939, fallback: "Are you sure you want to remove {0}?" },
  doYouWantToRemoveGroup: { id: 43939, fallback: "Are you sure you want to remove {0}?" },
  // groupName: { fallback: "Group name" },
  // userSearch: { fallback: "Search" },
  // changeUserPassword: { fallback: "Change password" },
  // activateUser: { fallback: "Activate user" },
  // deactivateUser: { fallback: "Deactivate user" },
  // deleteUser: { fallback: "Delete user" },
  // pickUser: { fallback: "Pick user" },
  // userDetails: { fallback: "User details" },
  newUserCreated: { id: 43943, fallback: "New user was successfully created" },
  userRemoved: { id: 43944, fallback: "User was removed" },
  newGroupCreated: { id: 43945, fallback: "User group was successfully saved" },
  groupRemoved: { id: 43946, fallback: "User group was removed" },
  groupCouldNotBeDeleted: {
    id: 43942,
    fallback: "User group could not be deleted. User group may contain users.",
  },
  userUserGroupsUpdated: { fallback: "User user groups were updated successfully" },
  forcePwChange: { id: 43947, fallback: "Force user to change password on next login" },
  accountStatus: { id: 43948, fallback: "Account status" },
  userActivated: { id: 43949 },
  /** */

  YJ: { id: 32229 }, // Build 3.4.6.2 (Issue #33350)
  productChangeError: { fallback: "Product change is not possible." },
  userValidInProvider: { id: 44295, fallback: "User is valid in current publisher" },
  authorizeUserInPublisher: { id: 44296, fallback: "Authorize user to current publisher" },
  authorizeUserInPublisherSuccess: { fallback: "User was successfully added to provider." },
  authorizeUserInPublisherError: { fallback: "User is unable to add in provider." },
  parametersManagement: { id: 44300, fallback: "Parameters Management" },
  codeGroupNo: { id: 32360, fallback: "Code Group Number" },
  paperCode: { id: 3099, fallback: "Paper Code" },
  codeNo: { id: 44305, fallback: "Code Number" },
  codeValue: { id: 44306, fallback: "Code Value" },
  newParameterGroup: { id: 44302, fallback: "New parameter group" },
  manageParametersAndGroups: { id: 44301, fallback: "Manage parameters and groups" },
  addNewParameter: { id: 44303, fallback: "Add new parameter" },
  viewList: { id: 44304, fallback: "View list >>" },
  removingParamsGroup: { id: 44307, fallback: "Remove parameters group" },
  doYouWantToRemoveGroup: {
    id: 15001,
    fallback: "Are you sure you want to remove parameters group?",
  },
  paramsGroupRemoved: { id: 44308, fallback: "Parameters Group was removed" },
  removingParameter: { id: 44309, fallback: "Remove parameter" },
  doYouWantToRemoveParameter: { id: 15001, fallback: "Are you sure you want to remove parameter?" },
  parameterRemoved: { id: 44310, fallback: "Parameter was removed" },
  codeGroupName: { id: 30173, fallback: "Code group name" },
  notes: { id: 30649, fallback: "Notes" },
  paramsGroupAdded: { id: 44311, fallback: "New parameters group was successfully created" },
  startdate: { id: 3193, fallback: "Start date" },
  enddate: { id: 3194, fallback: "End date" },
  parametersAdded: { id: 44312, fallback: "New Parameter was successfully created" },
  newParamsGroup: { id: 44302, fallback: "New parameters group" },
  EditParamsGroup: { id: 44313, fallback: "Edit parameters group" },
  newParameter: { id: 44303, fallback: "New parameter" },
  editParameter: { id: 44315, fallback: "Edit parameter" },
  parametersGroup: { id: 30323, fallback: "Paramters Group" },
  paramsGroupEdited: { id: 44314, fallback: "Parameters group was successfully edited." },
  parameterEdited: { id: 44314, fallback: "Parameter was successfully edited." },

  //reports
  createReport: { id: 44339, fallback: "Create report" },
  reportsRequests: { id: 3125, fallback: "Reports" },
  code: { id: 3144, fallback: "Code" },
  userid: { fallback: "User" },
  started: { id: 15302, fallback: "Started" },
  ready: { id: 40989, fallback: "Ready" },
  status: { fallback: "Status" },
  publicationOrGroup: { id: 43824, fallback: "Publication or Group" },
  reportFilePath: { id: 43808, fallback: "Report file path" },
  logFilePath: { id: 43809, fallback: "Log file path" },
  errorFilePath: { id: 43810, fallback: "Err file path" },
  own: { id: 40848, fallback: "Own" },
  from: { id: 30025, fallback: "From" },
  to: { id: 41195, fallback: "To" },
  fromPlaceholder: { fallback: "Select from date" },
  toPlaceholder: { fallback: "Select to date" },
  change: { fallback: "Change" },
  changeStatus: { fallback: "Change status" },
  delete: { fallback: "Delete" },
  openReport: { fallback: "Open report" },
  openLogFile: { fallback: "Open log file" },
  openErrFile: { fallback: "Open error file" },
  reprequest1: { fallback: "Report request 1" },
  reprequest2: { fallback: "Report request 2" },
  reprequest3: { fallback: "Report request 3" },
  nextrun: { fallback: "Next run" },
  reportRemoved: { fallback: "Report was removed." },
  reportEdited: { fallback: "Report was updated successfully." },
  reportAdded: { fallback: "Report was added successfully." },
  noReportIsSelected: { id: 32064, fallback: "No report is selected." },
  startupParameters: { id: 31619, fallback: "Startup parameters" },
  publication: { id: "52", fallback: "Publication" },
  startTime: { id: 1204, fallback: "Start time" },
  exportReport: { id: 41586, fallback: "Export Report" },
  repReqRenewal: { id: 30122, fallback: "Report request renewal" },
  schedule: { id: 40711, fallback: "Schedule" },
  noRenewal: { id: 44343, fallback: "No renewal" },
  renewFirstBankDay: { id: 44344, fallback: "Renew first bank day of next month" },
  renewAfter: { id: 44341, fallback: "Renew after" },
  minutes: { id: 44342, fallback: "minutes" },
  moveReportDataLimit: { id: 1209, fallback: "Move data limits of report" },
  reportStartOption: { id: 44340, fallback: "Report Start Options" },
  startReport: { id: 44339, fallback: "New Report" },
  searchReports: { id: 505, fallback: "Search reports" },
  renewalLimit: { fallback: "Renewal limit" },
  editReport: { id: 44348, fallback: "Edit Report" },
  quickFilter: { fallback: "Quick filters" },
  customerPhoenNoQuestion: {
    id: 40879,
    fallback: "Phone number is required ! Do you want to continue with default value",
  },
  receiptno: { id: 607, fallback: "Payment receiptno" },

  // Product Management
  product: { fallback: "Product" },
  productPackages: { fallback: "Product Packages" },
  productManagement: { fallback: "Product Management" },
  addNewPaper: { fallback: "Add new paper" },
  addNewProduct: { fallback: "Add new product" },
  distributionPubCod: { fallback: "Distribution pubcod" },
  productnr: { fallback: "Product nr" },
  deliverFrequence: { fallback: "Deliver Frequence" },
  vatCode: { fallback: "VAT code" },
  url: { fallback: "Url" },
  addNewProductPackages: { fallback: "Add new product packages" },
  packageName: { fallback: "Package name" },
  primaryInfo: { fallback: "Primary Information" },
  additionalInfo: { fallback: "Additional Information" },
  othersInfo: { fallback: "Others" },
  publicationName: { fallback: "Publication name" },
  pricingType: { fallback: "Pricing type" },
  monthlyBasedPricing: { fallback: "Monthly based pricing" },
  issueBasedPricing: { fallback: "Issue based pricing" },
  monthlyPricePerItem: { fallback: "Monthly price / item" },
  paperBelongsToGroup: { fallback: "Paper belongs to group" },
  reportGroup: { fallback: "Report group" },
  distributionGroup: { fallback: "Distribution group" },
  invoiceGroup: { fallback: "Invoice group" },
  paperClassification: { fallback: "Paper classification" },
  classification1: { fallback: "Classification 1" },
  classification2: { fallback: "Classification 2" },
  classification3: { fallback: "Classification 3" },
  businessUnitId: { fallback: "Business unit id" },
  extratexts: { fallback: "Extratexts" },
  text1: { fallback: "Text 1" },
  text2: { fallback: "Text 2" },
  text3: { fallback: "Text 3" },
  text4: { fallback: "Text 4" },
  text5: { fallback: "Text 5" },
  issueWeightFactor: { fallback: "Issue weight factor avaiable (double number)" },
  winsale: { fallback: "Winsale" },
  doYouWantToRemovePaperCode: { fallback: "Are you sure you want to remove {0}?" },
  removingPaperCode: { fallback: "Remove paper code" },
  paperCodeRemoved: { fallback: "Paper code was removed" },
  newPaperCode: { fallback: "New paper code" },
  EditPaperCode: { fallback: "Edit paper code" },
  paperCodeAdded: { fallback: "New paper code was successfully created" },
  paperCodeEdited: { fallback: "Paper code was successfully edited." },
  newProduct: { fallback: "New product" },
  product: { fallback: "Product" },
  digitalProduct: { fallback: "Digital product" },
  deliveryFrequence: { fallback: "Delivery frequence" },
  productConfiguration: { fallback: "Product configuration" },
  extra: { fallback: "Extra" },
  singleCopyOrRetailer: { fallback: "Single copy / retailer" },
  district: { fallback: "District" },
  bundleSheetText: { fallback: "Bundle sheet text" },
  issn: { fallback: "ISSN" },
  removingProduct: { fallback: "Remove product" },
  doYouWantToRemoveProduct: { id: 15001, fallback: "Are you sure you want to remove product?" },
  usePublishingCalendar: { fallback: "Use publishing calendar" },
  editProduct: { fallback: "Edit product" },
  Dagligen: { id: 31298 },
  Veckoligt: { id: 31299 },
  "2 gg/mån": { id: 31300 },
  Månadligen: { id: 31301 },
  "4 gg per år": { id: 31302 },
  Årligen: { id: 31303 },
  imageUploadSuccess: { fallback: "Image upload successfully." },
  imageUploadFail: { fallback: "Image upload failed." },
  inactive: { fallback: "Inactive" },
  packageComponents: { fallback: "Package Components" },
  creditCategory: { fallback: "Credit category" },
  clearFilters: { fallback: "Clear Filters" },
  paidProduct: { fallback: "Paid product" },
  discountAsMainProduct: { fallback: "Discount as mian product" },
  removingProductPackages: { fallback: "Remove product packages" },
  doYouWantToRemoveProductPackages: {
    id: 15001,
    fallback: "Are you sure you want to remove product packages?",
  },
  productEdited: { fallback: "Product was successfully edited." },
  productAdded: { fallback: "New product was successfully created" },
  productRemoved: { fallback: "Product was removed" },
  productPackagesEdited: { fallback: "Product packages was successfully edited." },
  productPackagesAdded: { fallback: "New product packages was successfully created" },
  productPackagesRemoved: { fallback: "Product packages was removed" },
  newProductPackages: { fallback: "New product packages" },
  editProductPackages: { fallback: "Edit product packages" },
  removingPackageProducts: { fallback: "Remove package products" },
  doYouWantToRemovePackageProducts: {
    id: 15001,
    fallback: "Are you sure you want to remove package products?",
  },
  packageProductsRemoved: { fallback: "Package products was removed" },
  packageProductsAdded: { fallback: "New package products was successfully created" },
  subkindStandingRule: { fallback: "Subkind standing rule" },
  subkindPeriodicRule: { fallback: "Subkind periodic rule" },
  digitalProductSleepRule1: { fallback: "Credit in money" },
  digitalProductSleepRule2: { fallback: "No credit" },
  digitalProductSleepRule3: { fallback: "Credit time not reduced, credit days as usual" },
  digitalProductSleepRule4: {
    fallback:
      "Credit time is reduced in relation to total package price and digital price as subscribed",
  },
  digitalProductSleepRule5: {
    fallback:
      "Credit time is reduced in relation to total package price and digital product’s normal price",
  },
  newPackageProducts: { fallback: "New package products" },
  editPackageProducts: { fallback: "Edit  package products" },
  imageUploadRetry: { fallback: "Image upload failed. Please re upload image." },
  sectionAvailableInEditMode: { fallback: "This section will be available in edit mode" },
  selectReportGroups: { fallback: "Select report group ..." },
  selectDistributionGroups: { fallback: "Select distribution group ..." },
  selectInvoiceGroups: { fallback: "Select invoice group ..." },
  unselect: { fallback: "-" },
  submitAddProductFormWithCoProds: { id: 44381, fallback: "You have not selected any campaign co-products, are you sure?" },
  add30Days: { fallback: "+ 30 days" },
  addressLabel: { fallback: "Address label" },

  //PriceList Management
  priceListManagement: { fallback: "Price List Management" },
  subsType: { fallback: "Subs Type" },
  subsTypeName: { fallback: "Subs Type Name" },
  subsTypeCode: { fallback: "Subs Type Code" },
  addNewPriceList: { fallback: "Add new price list" },
  addNewPriceGroup: { fallback: "Add new price group" },
  priceListPrimaryInfo: { fallback: "Primary Information" },
  editPriceList: { fallback: "Edit price list" },
  newPriceList: { fallback: "New price list" },
  priceListId: { fallback: "Pricelist ID" },
  priceInstallments: { fallback: "Price installments" },
  includeVAT: { fallback: "Include VAT" },
  priceInstallmentPlan: { fallback: "Price installment plan" },
  packageSum: { fallback: "Package sum" },
  digital: { fallback: "Digital" },
  priceListAdded: { fallback: "New price list was successfully created" },
  priceListEdited: { fallback: "Price list was successfully edited." },
  priceListRemoved: { fallback: "Price list was removed" },
  doYouWantToremovePriceList: { fallback: "Are you sure you want to remove price list?" },
  removingPriceList: { fallback: "Remove price list" },
  renewalPriceGroup: { fallback: "Renewal price group" },
  comparePriceGroup: { fallback: "Compare price group" },
  singleCopyCategory: { fallback: "Single copy category" },
  addNewSubsType: { fallback: "Add new subs type" },
  priceGroupAdded: { fallback: "New price group was successfully created" },
  priceGroupEdited: { fallback: "Price group was successfully edited." },
  priceGroupRemoved: { fallback: "Price group was removed" },
  editPriceGroup: { fallback: "Edit price group" },
  newPriceGroup: { fallback: "New price group" },
  priceGroupName: { fallback: "Price group name" },
  doYouWantToRemovePriceGroup: { fallback: "Are you sure you want to remove price group?" },
  renewalSubscription: { fallback: "Renewal subscription" },
  groupSubscription: { fallback: "Group subscription" },
  membershipDiscount: { fallback: "Membership discount" },
  setupDiscount: { fallback: "Setup discount" },
  subsTypeAdded: { fallback: "New subs type was successfully created" },
  subsTypeEdited: { fallback: "Subs type  was successfully edited." },
  subsTypeRemoved: { fallback: "Subs type was removed" },
  doYouWantToRemoveSubsType: { fallback: "Are you sure you want to remove {0}?" },
  removingSubsType: { fallback: "Remove subs type" },
  editSubsType: { fallback: "Edit Subs Type" },
  newSubsType: { fallback: "New Subs Type" },
  discountAsMoney: { fallback: "Discount as money" },
  groupSubscriptions: { fallback: "Group subscriptions" },
  membershipDiscount: { fallback: "Membership discount" },
  stepUpDiscount: { fallback: "Step-up discount" },
  notASingleCopyPriceGroup: { fallback: "Not a single-copy price-group", id: 41173 },
  externallyinvoicedSingleCopies: { fallback: "Externally invoiced Single-copies", id: 41174 },
  singleCopySubscriptionWith051Discount: { fallback: "Single-copy subscription with 0-50% discount", id: 41175 },
  singleCopySubscriptionWith5185Discount: { fallback: "Single-copy subscription with 51-85% discount", id: 41176 },
  invoicingPlan: { fallback: "Invoicing Plan" },
  doYouWantToRemoveInvoicingPlan: { fallback: 'Do you want to remove invoicing plan' },
  removingInvoicingPlan: { fallback: "Removing invoicing plan" },
  invoices: { fallback: "Invoices" },
  reminder: { fallback: "Reminder" },
  collections: { fallback: "Collections" },
  provision: { fallback: "Provision" },
  single: { fallback: "Single" },
  invoiceSum: { fallback: "Invoice Sum" },
  publicationGroup: { fallback: "Publication Group" },
  year: { fallback: "Year" },
  executed: { fallback: "Executed" },
  collectionInvoices: { fallback: "Collection Invoices" },
  editDelete: { fallback: "Edit/Delete" },
  collecting: { fallback: "Collecting" },
  stopUnpaidSubs: { fallback: "Stop Unpaid Subs" },
  areaList: { fallback: "Area List" },
  printOrder: { fallback: "Print Order" },
  salesPersonNo: { fallback: "Salesperson number" },
  extraDaysForForeignCust: { fallback: "Extra days for foreign customers" },
  feeReminder: { fallback: "Fee/Reminder" },
  minimumAmount: { fallback: "Minimum Amount" },
  otherProducts: { fallback: "Other Products" },
  normalInvoices: { fallback: "Normal Invoices" },
  signleCopyInvoices: { fallback: "Single Copy Invoices" },
  fee: { fallback: "Fee" },
  normalInvoicing: { fallback: "Normal Invoicing" },
  normalInvoicingDesc1: { fallback: "New standard and periodic subscriptions invoice" },
  normalInvoicingDesc2: { fallback: "Renewed standing subscriptions to be invoiced" },
  preDeterminedDueDate: { fallback: "Pre Determined Due Date" },
  preDeterminedStartText: { fallback: "The invoicing period starts today or earlier, and has not yet been invoiced" },
  preDeterminedDueText: { fallback: "The due date is set" },
  // collectInvoice: { fallback: "Collect Invoice" },
  collectInvoiceDesc: { fallback: "Collect Invoice - Mediates Invoice  " },
  dueDateOther: { fallback: "Due Date (Other)" },
  groundDate: { fallback: "Ground Date" },
  // days: {fallback: "Days"},
  lastDueDate: { fallback: "Last Due Date taken" },
  minInvoice: { fallback: "Minimum Invoice Amount" },
  minAmount: { fallback: "Minimum Amount for last period" },
  subscriptionsToggle: { fallback: "Suscriptions" },
  // otherProducts: {fallback: "Other Products"},
  invoiceGeneralCoProducts: { fallback: "Invoice general co-products" },
  limitations: { fallback: "Limitations" },
  campaignid: { fallback: "Campaign ID" },
  campaignname: { fallback: "Campaign Name" },
  campaignno: { fallback: "Campaign No" },
  additionalFields: { fallback: "Additional Fields" },
  maxNrInvoices: { fallback: "Max Nr Invoices" },
  cusNoFrom: { fallback: "Customer no from" },
  cusNoTo: { fallback: "Cutomer no to" },
  customerInfo: { fallback: "Customer Information" },
  // running: {fallback: "Running"},
  // pcs: {fallback: "pcs"},
  comment: { fallback: "Comment" },
  dueDateSet: { fallback: "The due date of the invoice is also set" },
  // lastDueDate: {fallback: "Last due date taken"},
  newInvoiceSubForm: { fallback: "New Invoices Sub Form" },
  edInvoiceSubForm: { fallback: "Edit Invoices Sub Form" },
  // days: {fallback: "days"},



  ground: { fallback: "=Ground+" },
  firstReminder: { fallback: "1st Reminder" },
  secondReminder: { fallback: "2nd Reminder" },
  dueLatest: { fallback: "Due Latest" },
  remindDay: { fallback: "Remind Day" },
  afterFirstReminder: { fallback: "After the 1st reminder" },
  afterSecondReminder: { fallback: "After the 2nd reminder" },
  stopDate: { fallback: "Stop Date" },
  limit: { fallback: "Limit" },
  areasCities: { fallback: "Areas/Cities" },
  homeCountry: { fallback: "Home Country" },
  foreign: { fallback: "Foreign" },
  areaCityZip: { fallback: "Area/City/ZipCode" },
  any: { fallback: "Any" },
  area: { fallback: "Area" },
  costCorrelation: { fallback: "Cost Correlation" },
  subsPricelist: { fallback: "Subs Price List" },
  selectedLimits: { fallback: "Selected Limits" },
  invoiceFee: { fallback: "Invoice Fee" },
  invoicesType: { fallback: "Invoices Type" },
  invoiceType: { fallback: "Invoice Type" },
  standardFinalInvoices: { fallback: "Standard final invoices" },
  renewalInvoices: { fallback: "Renewal Invoices" },
  between: { fallback: "Between" },
  both: { fallback: "Both" },
  normalInvoice: { fallback: "Normal Invoice" },
  normalSubsPriceList: { fallback: "The normal subscription price list" },
  validAtInvoicePeriod: { fallback: "Valid at invoice period starting" },
  priceListPeriodStart: { fallback: "Pricelist at period start" },
  priceListCancelDate: { fallback: "Pricelist at cancellation date" },
  basedOnInvoicedPrice: { fallback: "Based on invoiced price" },
  campaignUsesSubsPricelist: { fallback: "Campaign uses the subscription price list" },
  campaignPriceList: { fallback: "Campaign pricelist" },
  noInvoicingPlanFound: { fallback: "No invoicing plan found" },
  invoiceProgram: { fallback: "Invoicing Program" },
  editInvoicingProgram: { fallback: "Edit Invoicing Program" },
  createInvoicingProgram: { fallback: "Create Invoicing Program" },
  invoicingProgramEdited: { fallback: "Invoicing program was successfully edited" },
  invoicingProgramAdded: { fallback: "Invoicing program was added successfully" },
  createInvoicingPlan: { fallback: "Create Invoicing Plan" },
  editInvoicingPlan: { fallback: "Edit Invoicing Plan" },
  invoicingPlanEdited: { fallback: "Invoicing plan was successfully edited" },
  invoicingPlanAdded: { fallback: "Invoicing plan was added successfully" },
  invoicingProvisionAdded: { fallback: "Invoicing Provision was added successfully" },
  invoicingControlAdded: { fallback: "Invoicing Control was added successfully" },
  invoicingFeepGroups: { fallback: "Invoicing Fee Groups were added successfully" },
  invoicingCampaigns: { fallback: "Invoicing Campaigns were added successfully" },
  invoicingSingleControlAdded: { fallback: "Invoicing Single copy was added successfully" },




  order: { fallback: "Order" },
  executionDate: { fallback: "Execution Date" },
  paymentExecutionDate: { fallback: "Payment Execution Date" },
  // payDate: {fallback: "Pay date"},
  minCommission: { fallback: "Minimum Commissions" },
  lastPayment: { fallback: "The last payment" },
  // printOrder: {fallback: "Print Order"},
  postcode: { fallback: "Postcode" },
  // name: {fallback: "Name"},
  // district: {fallback: "District"},
  // invoiceNo: {fallback: "Invoice No."},
  salespersonNo: { fallback: "Salesperson number" },
  paymentMethods: { fallback: "Payment Methods" },
  giro: { fallback: "Giro" },
  viaLine: { fallback: "Via Line" },
  // accountInfo: {fallback: "Account Information"},
  // account: {fallback: "Account"},
  // default: {fallback: "Default"},
  monetplannio: { fallback: "MONETPLANNIO" },
  rundate: { fallback: "RUNDATE" },
  runDate: { fallback: "Run Date" },

  accno_bank: { fallback: "ACCNO_BANK" },
  accno_acc: { fallback: "ACCNO_ACC" },
  primaryacc: { fallback: "PRIMARYACC" },
  display: { fallback: "DISPLAY" },
  primaryaccdisplay: { fallback: "PRIMARYACCDISPLAY" },
  stamp_user: { fallback: "STAMP_USER" },
  stamp_date: { fallback: "STAMP_DATE" },
  stamp_program: { fallback: "STAMP_PROGRAM" },
  feeGroups: { fallback: "Fee Groups" },
  feeGroupCode: { fallback: "Fee Group Code" },
  feeGroup: { fallback: "Fee Group" },
  eventGroups: { fallback: "Event Groups" },
  primary: { fallback: "Primary" },
  // invoiceDate: {fallback: "Invoice Date"},
  // limitDate: {fallback: "Limit Date"},
  // dueDate: {fallback: "Due Date"},
  orderNumbers: { fallback: "Order number(s) ( to be invoiced ) separated by comma" },
  newInvoiceSingleCopyForm: { fallback: "New Single Copy Form" },
  editInvoiceSingleCopyForm: { fallback: "Edit Single Copy Form" },
  newInvoiceProvisionForm: { fallback: "New Provision Form" },
  editInvoiceProvisionForm: { fallback: "Edit Provision Form" },
  createPlan: { fallback: "Create Plan" },
  invoicingPlanRemoved: { fallback: "Invoicing plan removed" },
  invoicingProgramRemoved: { fallback: "Invoicing program removed" },
  removingInvoicingProgram: { fallback: "Removing invoicing program" },
  doYouWantToRemoveInvoicingProgram: { fallback: "Do you want to remove invoicing program" },
  createProgram: { fallback: "Create Program" },
  allNewsPapersInPaperGroup: { fallback: "All newspapers in the newspaper group" },





  standItemQuantity: { fallback: "Stand item quantity" },
  periodicItemQuantity: { fallback: "Periodic item quantity" },
  doYouWantToremovePriceRow: { fallback: "Are you sure you want to remove price row?" },
  removingPriceRow: { fallback: "Remove price row" },
  periodAmount: { fallback: "Period amount" },
  circulationId: { fallback: "Circulation id" },
  priceRowAdded: { fallback: "Price row was successfully edited" },
  priceRowEdited: { fallback: "Price row was successfully created" },
  priceRowRemoved: { fallback: "Price row was removed" },
  newStandingSubscription: { fallback: "New standing subscription" },
  editStandingSubscription: { fallback: "Edit standing subscription" },
  newPriceRow: { fallback: "New price row" },
  editPriceRow: { fallback: "Edit price row" },
  addNewPriceRow: { fallback: "Add new price row" },
  
  planyear: { fallback: "Plan year" },


  firstDun: { fallback: "1st Dun" },
  secondDun: { fallback: "2nd Dun" },
  erinnerung: { fallback: "2-Erinnerung" },
  salesmanFee: { fallback: "Salesman Fee" },
  intervalWeeks: { fallback: "Interval in weeks" },
  everyDay: { fallback: "Every Day" },

  maturity: { fallback: "Maturity"}



};

export default translationData;
