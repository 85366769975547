import React from "react";
import { useSelector } from "react-redux";

import { selectCurrentLocale, selectLocales } from "@app/locale/locale.selector.js";
import { switchLocale } from "@utils/locale.js";

import Dropdown from "../ui/Dropdown.jsx";

/**
 * LocaleSelection renders dropdown component which can be used
 * to change the output locale.
 *
 * Usage:
 * ```
 * <LocaleSelection />
 * ```
 */
const LocaleSelection = () => {
  const locales = useSelector(selectLocales);
  const current = useSelector(selectCurrentLocale);

  return (
    <Dropdown
      sendSelection={(localeSelection) => switchLocale(localeSelection)}
      items={locales}
      placeholder={current}
    />
  );
};

export default LocaleSelection;
