import * as actions from "../actions/actionTypes.js";

const defaultState = {
  version:"",
};

const versionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_VERSION_INFORMATION: {
      return { ...state, version: action.versionInformation };
    }
    default:
      return state;
  }
};

export default versionReducer;
