import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import { isValidNumber, removeDotsAndCommas } from "@utils/validate";

/**
 * Reusable NumberInput component. Used to collect numeric user input.
 * This component can be used in redux-form or alone (parent handling state).
 * Use prop isInt={true} to collect integer type number input. Omitting isInt
 * or setting it to be false allows user to enter float type number input.
 *
 * Usage example redux-form:
 * ```
 * <Field
 *   name='age'
 *   component={FormInput}
 *   type='number'
 *   label='Age'
 * />
 * ```
 *
 * Usage example alone:
 * ```
 * <NumberInput
 *   onChange={(number) => this._handleNumberInput(number)}
 *   value={this.state.numberInput}
 *   placeholder='Age'
 *   isInt={true}
 * />
 * ```
 */
class NumberInput extends React.Component {
  _handleInputValueChange(e) {
    const input = e.target.value;
    const func = this.props.input ? this.props.input.onChange : this.props.onChange; // redux-form check
    // Check whether this component is supposed to collect integer or float numbers
    isValidNumber(input) && func(this.props.isInt ? removeDotsAndCommas(input) : input);
  }

  constructor(props) {
    super(props);
    this._handleInputValueChange = this._handleInputValueChange.bind(this);
  }

  render() {
    // onChange and isInt needs to be specified here to avoid unknown prop being passed to <Input>
    const { onChange, meta, isInt, input, hideZero, ...rest } = this.props;
    return (
      <Input
        {...rest}
        {...input}
        value={
          input
            ? hideZero && (input.value === 0 || input.value === "0")
              ? ""
              : input.value
            : this.props.value
        }
        onChange={this._handleInputValueChange}
        type="text"
      />
    );
  }
}

NumberInput.propTypes = {
  onChange: PropTypes.func /* Callback function to handle number change */,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]) /* Optional default value, can be number like 123 or string like '123'. Use empty string for undefined value */,
  placeholder: PropTypes.string /* Optional placeholder text */,
  isInt: PropTypes.bool /* Optionally specify that number needs to be integer, default is float */,
  input: PropTypes.any,
  meta: PropTypes.object,
  hideZero: PropTypes.bool,
};

export default NumberInput;

/**
 * The component OptimizedNumberInput is meant to be used with redux-form. It
 * optimizes the NumberInput by stripping unnecessary redux-form Field callbacks.
 *
 * It DOESN'T trigger onChange on every keypress, which makes redux-form faster
 * and more responsive, especially with larger forms. It changes the redux-form
 * values onBlur and when enter is pressed, i.e. the form submitted.
 *
 * NOTE: There can be some unwanted side effects because many callbacks are
 * disabled, so use with caution and test forms properly before committing!
 */
// class OptimizedNumberInput extends React.Component {
//   // Listen enter key presses and call redux-form onChange function on key press.
//   _onKeyDown(ev, onChange) {
//     if (ev.key === 'Enter' && !ev.shiftKey) {
//       onChange(ev.target.value);
//       return;
//     }
//   }
//
//   // On change check that the new value is valid number
//   _onChange(ev) {
//     let value = ev.target.value;
//     if (this.props.isInt && !isValidInt(value) || !this.props.isInt && !isValidNumber(value)) {
//       ev.target.value = this.value;
//     } else {
//       this.value = value;
//     }
//   }
//
//   constructor(props) {
//     super(props);
//
//     // Keep track of the input value internally so that the previous valid value
//     // can be restored when necessary.
//     this.value = '';
//
//     this.onKeyDown = (ev) => { this._onKeyDown(ev, props.input.onChange); };
//     this.onChange = (ev) => { this._onChange(ev); };
//   }
//
//   render() {
//     // Take only required redux-form functions from 'input' prop
//     const { input: { onBlur, onFocus }, meta, isInt, ...rest } = this.props;
//
//     return (
//       <Input
//         onKeyDown={ this.onKeyDown }
//         onChange={ this.onChange }
//         onBlur={ onBlur }
//         onFocus={ onFocus }
//         type='number'
//         { ...rest }
//       />
//     );
//   }
// }

// export { OptimizedNumberInput };
