import * as actions from "../actions/actionTypes.js";
import { randomInt } from "../utils/random.js";

const feedbackState = {
  id: null,
  feedbacks: {},
  selected: null,
};

const defaultState = {
  customer: { ...feedbackState },
  user: { ...feedbackState },
  coDistribution: { ...feedbackState },
  newFeedbackActive: false,
  eventType: "",
  savedValue: "0",
  target: "",
  subscription: {}, // Build 3.6.3.4 (Issue #32668)
  argument: {}, // Build 3.6.4.1 (Issue #32668)
};

const feedbackReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_FEEDBACKS: {
      const feedbacks = {};
      for (const item of action.feedbacks) {
        feedbacks[item.reclaimno] = { ...item };
      }

      const target = { id: action.id, feedbacks: feedbacks, selected: null };
      const feedback = { ...state, [action.target]: target };

      return { ...feedback };
    }

    case actions.SELECT_FEEDBACK: {
      const target = { ...state[action.target], selected: action.feedback };
      const feedback = { ...state, [action.target]: target };

      return { ...feedback };
    }

    case actions.CLEAR_FEEDBACKS: {
      if (action.target === null) {
        return { ...defaultState };
      }

      const target = { ...defaultState[action.target] };
      return {
        ...state,
        [action.target]: target,
        newFeedbackActive: defaultState.newFeedbackActive,
        eventType: defaultState.eventType,
        savedValue: defaultState.savedValue,
      };
    }

    // Save returned feedbackno and close the feedback modal
    case actions.FEEDBACK_SAVED: {
      const feedback = {
        ...state,
        savedValue: action.value === null ? randomInt().toString() : action.value,
        newFeedbackActive: false,
        target: "",
      };

      return { ...feedback };
    }

    case actions.CLOSE_NEW_FEEDBACK:
      return { ...state, newFeedbackActive: false, target: "" };

    case actions.NEW_FEEDBACK: {
      const feedback = {
        ...state,
        eventType: action.eventType,
        newFeedbackActive: !state.newFeedbackActive,
        target: action.target,
        subscription: action.subscription, // Build 3.6.3.4 (Issue #32668)
      };

      return { ...feedback };
    }

    // Build 3.6.4.1 (Issue #32668)
    case actions.LOAD_ARGUMENT: {
      return { ...state, argument: action.argument };
    }

    default:
      return state;
  }
};

export default feedbackReducer;
