import * as actions from "../actions/actionTypes.js";

const contactState = {
  id: null,
  contacts: {},
  selected: null,
};

const defaultState = {
  customer: { ...contactState },
  user: { ...contactState },
};

const customerContactsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_CUSTOMER_CONTACTS: {
      const contacts = {};
      for (const item of action.contacts) {
        contacts[item.contactno] = { ...item };
      }

      const target = { id: action.id, contacts: contacts, selected: null };
      const contact = { ...state, [action.target]: target };

      return { ...contact };
    }

    case actions.SELECT_CUSTOMER_CONTACT: {
      const target = { ...state[action.target], selected: action.contact };
      const contact = { ...state, [action.target]: target };

      return { ...contact };
    }

    case actions.CLEAR_CUSTOMER_CONTACTS: {
      if (action.target === null) {
        return { ...defaultState };
      }

      const target = { ...defaultState[action.target] };
      return { ...state, [action.target]: target };
    }

    default:
      return state;
  }
};

export default customerContactsReducer;
