import * as actions from "./locale.action";

const defaultState = {
  locales: [],
  current: "",
};

const localeReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_AVAILABLE_LOCALES:
      return { ...state, locales: action.payload };

    case actions.SET_LOCALE:
      return { ...state, current: action.payload };

    default:
      return { ...state };
  }
};

export default localeReducer;
