import * as actions from "./parameters.action";

const defaultState = {
  initialized: false,
  // Users
  users: [],
  paramsGroupLoading: false,
  removingUser: false,
  parametersGroup: [],
  parameters: null,
  removingParamsGroup: false,
  removingParameter: false,
  paperCodes: [],
  parameterLoading: false,
  parameter: {},

};

const adminReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.ADMIN_USERS_INITIALIZED: {
      return { ...state, initialized: true };
    }

    case actions.ADMIN_PARAMS_IS_LOADING_PARAMETERS_GROUP: {
      return { ...state, parametersGroup: [], paramsGroupLoading: true };
    }

    case actions.ADMIN_PARAMS_SET_ALL_PARAMETERS_GROUP: {
      return { ...state, parametersGroup: payload, paramsGroupLoading: false };
    }

    case actions.ADMIN_PARAMS_IS_LOADING_PARAMETERS: {
      return { ...state, paramsLoading: true };
    }

    case actions.ADMIN_PARAMS_SET_ALL_PARAMETERS: {
      return { ...state, parameters: payload, paramsLoading: false };
    }

    case actions.ADMIN_PARAMS_SET_REMOVING_PARAMS_GROUP: {
      return { ...state, removingParamsGroup: payload || false };
    }

    case actions.ADMIN_PARAMS_PARAMS_GROUP_REMOVED: {
      const parametersGroup = state.parametersGroup.filter((pg) => pg.codegroupno !== payload.codegroupno);
      return { ...state, parametersGroup };
    }

    case actions.ADMIN_PARAMS_SET_REMOVING_PARAMETER: {
      return { ...state, removingParameter: payload || false };
    }

    case actions.ADMIN_PARAMS_PARAMETER_REMOVED: {
      const parameters = state.parameters.filter((pg) => pg.codeno !== payload.codeno);
      return { ...state, parameters };
    }

    case actions.ADMIN_PARAMS_PARAMS_GROUP_EDITED: {
      const parametersGroup = state.parametersGroup.map((pg) => {
        if (pg.codegroupno === payload.codegroupno) {
          return { ...pg, codegroupname: payload.codegroupname, notes: payload.notes }
        }
        return pg;
      });
      return { ...state, parametersGroup };
    }

    case actions.ADMIN_PARAMS_SET_ALL_PAPER_CODES: {
      console.log("SET_ALL_PAPER_CODES reducer")

      return { ...state, paperCodes: payload };
    }

    case actions.ADMIN_PARAMS_IS_LOADING_PARAMETER: {
      return { ...state, parameterLoading: true };
    }

    case actions.ADMIN_PARAMS_SET_PARAMETER: {
      return { ...state, parameter: payload, parameterLoading: false };
    }
    default:
      return state;
  }
};

export default adminReducer;
