/* eslint-disable array-callback-return */
import { push as routerPush } from "connected-react-router";
import { SubmissionError } from "redux-form";
import moment from "moment";

import store from "../app/store.js";
import * as actions from "./actionTypes.js";
import { notify } from "@app/notification/notification.action.js";
import { Fetcher, fetchWithRetry } from "../utils/fetcher";
import apiUrl from "../utils/url.js";
import { generateKey } from "../utils/random.js";
import { formatDate, momentToDateString, parseString } from "../utils/locale.js";
import { formatCurrency, sortObjects } from "../utils/validate.js";
import { tr } from "../utils/translation.js";

export const getVersionInformation = () => (dispatch) => {
  const url = apiUrl("system/version");

  return Fetcher.getJson(url).then((versionInformation) => {
    dispatch(updateVersionInformation(versionInformation));
  });
};

export const updateVersionInformation = (versionInformation) => {
  return { type: actions.UPDATE_VERSION_INFORMATION, versionInformation: versionInformation.api_version };
};