import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Col, Row } from "reactstrap";

import { tr } from "@utils/translation.js";

import { FormInput } from "@views/components/form/inputs.jsx";
import { SubmitButton } from "../ui/buttons.jsx";

/**
 * Form used to collect new e-mail address
 */
let ChangeEmailForm = (props) => {
  return (
    <form autoComplete="off" className="mb-3" onSubmit={props.handleSubmit}>
      <h3>{tr("changeEmail")}</h3>
      <Row>
        <Col sm={12}>
          <Field name="email" component={FormInput} label={tr("email")} />
        </Col>
        <Col sm={12}>
          <div className="float-right">
            <SubmitButton submitting={props.submitting} text={tr("replace")} />
          </div>
        </Col>
      </Row>
    </form>
  );
};

ChangeEmailForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  newEmail: state.form.changeEmailForm,
});

ChangeEmailForm = connect(mapStateToProps)(ChangeEmailForm);

export default reduxForm({
  form: "changeEmailForm",
})(ChangeEmailForm);
