import React from "react";
import PropTypes from "prop-types";

/**
 * Component to provide visual presentation about how weak or strong given password is.
 */
const PasswordStrength = ({ strength }) => {
  if (strength < 0) {
    return (
      <div className="password-strength-bar">
        <div className="password-light password-danger"></div>
        <div className="password-light"></div>
        <div className="password-light password-no-border"></div>
      </div>
    );
  } else if (strength === 0) {
    return (
      <div className="password-strength-bar">
        <div className="password-light password-warning"></div>
        <div className="password-light password-warning"></div>
        <div className="password-light password-no-border"></div>
      </div>
    );
  } else if (strength > 0) {
    return (
      <div className="password-strength-bar password-success">
        <div className="password-light"></div>
        <div className="password-light"></div>
        <div className="password-light password-no-border"></div>
      </div>
    );
  } else {
    return (
      <div className="password-strength-bar">
        <div className="password-light"></div>
        <div className="password-light"></div>
        <div className="password-light password-no-border"></div>
      </div>
    );
  }
};
PasswordStrength.propTypes = {
  strength: PropTypes.number,
};

export default PasswordStrength;
