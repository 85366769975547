import { postImage } from "@app/imageBank/imageBank.effect.js";
import { notify } from "@app/notification/notification.action.js";
import * as actionCreator from "@utils/actionCreator.js";
import { tr } from "@utils/translation.js";
import * as effect from "./invoicingProgram.effect.js";

const SLICE = "invoicing";

export const ADMIN_PRICE_IS_LOADING_ALL_INVOICING_PROGRAM = `${SLICE}/IS_LOADING_ALL_INVOICING_PROGRAM`;
export const ADMIN_PRICE_IS_LOADING_ALL_INVOICING_CONTROL = `${SLICE}/IS_LOADING_ALL_INVOICING_CONTROL`;
export const ADMIN_PRICE_SET_ALL_INVOICING_PROGRAM = `${SLICE}/SET_ALL_INVOICING_PROGRAM`;
export const ADMIN_PRICE_SET_INVOICING_PROGRAM = `${SLICE}/SET_INVOICING_PROGRAM`;
export const ADMIN_PRICE_SET_INVOICING_CONTROL = `${SLICE}/SET_INVOICING_CONTROL`;
export const ADMIN_PRICE_SET_INVOICING_SINGLE_CONTROL = `${SLICE}/SET_INVOICING_SINGLE_CONTROL`;
export const ADMIN_PRICE_SET_INVOICING_PROVISION = `${SLICE}/SET_INVOICING_SINGLE_PROVISION`;
export const ADMIN_PRICE_SET_INVOICING_SINGLE_CAMPAIGNS = `${SLICE}/SET_INVOICING_SINGLE_CAMPAIGNS`;
export const ADMIN_PRICE_SET_INVOICING_FEE_GROUPS = `${SLICE}/SET_INVOICING_FEE_GROUPS`;
export const ADMIN_PRICE_SET_INVOICING_FEEP_GROUPS = `${SLICE}/SET_INVOICING_FEEP_GROUPS`;
export const ADMIN_PRICE_SET_INVOICING_REMINDER = `${SLICE}/SET_INVOICING_REMINDER`;
export const ADMIN_IS_LOADING_ALL_INVOICING_PROGRAM = `${SLICE}/IS_LOADING_ALL_INVOICING_PROGRAM`;
export const ADMIN_SET_ALL_INVOICING_PROGRAM = `${SLICE}/SET_ALL_INVOICING_PROGRAM`;
export const ADMIN_SET_INVOICING_PROGRAM = `${SLICE}/SET_INVOICING_PROGRAM`;

export const TOGGLE_SUB_INVOICES_MODAL = `${SLICE}/TOGGLE_SUB_INVOICES_MODAL`;
export const TOGGLE_REMINDER_MODAL = `${SLICE}/TOGGLE_REMINDER_MODAL`;
export const TOGGLE_COLLECTION_MODAL = `${SLICE}/TOGGLE_COLLECTION_MODAL`;
export const TOGGLE_PROVISION_MODAL = `${SLICE}/TOGGLE_PROVISION_MODAL`;
export const TOGGLE_SINGLE_MODAL = `${SLICE}/TOGGLE_SINGLE_MODAL`;
export const TOGGLE_ADD_EDIT_PROGRAM_MODAL = `${SLICE}/TOGGLE_ADD_EDIT_PROGRAM_MODAL`;
export const TOGGLE_INVOICE_SUM_MODAL = `${SLICE}/TOGGLE_INVOICE_SUM_MODAL`;


export const requestAllInvoicingProgram = () => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_IS_LOADING_ALL_INVOICING_PROGRAM));

  const response = await effect.fetchAllInvoicingProgram();
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_ALL_INVOICING_PROGRAM, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_ALL_INVOICING_PROGRAM, response));
};

export const requestInvoicingProgram = (invoicingProgram) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_IS_LOADING_ALL_INVOICING_PROGRAM));

  const response = await effect.fetchInvoicingProgram(invoicingProgram);
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_SET_INVOICING_PROGRAM, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_SET_INVOICING_PROGRAM, response));
};

export const addInvoicingProgram = (invoicingProgram, callback = () => { }) => async (dispatch) => {
  const response = await effect.postInvoicingProgram(invoicingProgram);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("invoicingProgramAdded"), "success"));

  dispatch(requestInvoicingProgram(invoicingProgram));

};


export const ADMIN_INVOICING_PROGRAM_EDITED = `${SLICE}/INVOICING_PROGRAM_EDITED`;
export const editInvoicingProgram= (invoicingProgram, callback = () => { }) => async (dispatch) => {

  const response = await effect.putInvoicingProgram(invoicingProgram);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  callback();
  dispatch(notify(tr("invoicingProgramEdited"), "success"));
  // dispatch(actionCreator.createAction(ADMIN_INVOICING_PROGRAM_EDITED, invoicingProgram));

  dispatch(requestInvoicingProgram(invoicingProgram));

};

export const ADMIN_SET_REMOVING_INVOICING_PROGRAM= `${SLICE}/SET_REMOVING_INVOICING_PROGRAM`;
export const ADMIN_INVOICING_PROGRAM_REMOVED = `${SLICE}/INVOICING_PROGRAM_REMOVED`;

export const removeInvoicingProgram = (invoicingProgram, callback = () => { }) => async (dispatch) => {

  dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_PROGRAM, true));

  const response = await effect.deleteInvoicingProgram(invoicingProgram);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_PROGRAM, false));
    return;
  }
  callback();
  dispatch(notify(tr("invoicingProgramRemoved"), "warning"));
  dispatch(actionCreator.createAction(ADMIN_SET_REMOVING_INVOICING_PROGRAM, false));
  // dispatch(actionCreator.createAction(ADMIN_INVOICING_PROGRAM_REMOVED, invoicingProgram));

  dispatch(requestInvoicingProgram(invoicingProgram));
};

export const SELECT_INVOICE_PROGRAM_ROW = `${SLICE}/SELECT_INVOICE_PROGRAM_ROW`;
export const selectInvoiceProgramRow = (row) => async (dispatch) => {
  dispatch(actionCreator.createAction(SELECT_INVOICE_PROGRAM_ROW,row));
};

//Modals toggling actions
export const toggleSubInvoicesModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_SUB_INVOICES_MODAL,isOpen));
};

export const toggleReminderModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_REMINDER_MODAL,isOpen));
};

export const toggleCollectionModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_COLLECTION_MODAL,isOpen));
};

export const toggleProvisionModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_PROVISION_MODAL,isOpen));
};

export const toggleSingleModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_SINGLE_MODAL,isOpen));
};
export const toggleInvoiceSumModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_INVOICE_SUM_MODAL,isOpen));
};




export const requestInvoiceControl = (invoiceControl) => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_INVOICING_CONTROL));
  console.log('------------calling invoice control',invoiceControl)
  const response = await effect.fetchInvoiceControl(invoiceControl);
  console.log({response})
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_CONTROL, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_CONTROL, response));
};
export const requestInvoiceSingleControl = (invoiceSingleControl) => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_INVOICING_SingleCONTROL));
  console.log('------------calling invoice Singlecontrol',invoiceSingleControl)
  const response = await effect.fetchInvoiceSingleControl(invoiceSingleControl);
  console.log({response})
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_SINGLE_CONTROL, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_SINGLE_CONTROL, response));
};

export const requestInvoiceProvision = (invoiceProvision) => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_INVOICING_SingleCONTROL));
  console.log('------------calling invoice Singlecontrol',invoiceProvision)
  const response = await effect.fetchInvoiceProvision(invoiceProvision);
  console.log({response})
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_PROVISION, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_PROVISION, response));
};

export const requestSingleCampaigns = (invoiceCampaigns) => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_INVOICING_SingleCONTROL));
  console.log('------------calling invoice Singlecontrol',invoiceCampaigns)
  const response = await effect.fetchSingleCampaigns(invoiceCampaigns);
  console.log({response})
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_SINGLE_CAMPAIGNS, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_SINGLE_CAMPAIGNS, response));
};

export const requestFeeGroups = () => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_INVOICING_SingleCONTROL));
  const response = await effect.fetchFeeGroups();
  console.log({response})
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_FEE_GROUPS, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_FEE_GROUPS, response));
};
export const requestFeepGroups = (params) => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_INVOICING_SingleCONTROL));
  const response = await effect.fetchFeepGroups(params);
  console.log({response})
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_FEEP_GROUPS, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_FEEP_GROUPS, response));
};
export const requestInvoiceReminder = (params) => async (dispatch) => {

  // dispatch(actionCreator.createAction(ADMIN_PRICE_IS_LOADING_ALL_INVOICING_SingleCONTROL));
  const response = await effect.fetchInvoiceReminder(params);
  console.log({response})
  if (response.error) {
    dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_REMINDER, []));
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(actionCreator.createAction(ADMIN_PRICE_SET_INVOICING_REMINDER, response));
};

export const addInvoiceControl = (invoiceControl, callback = () => { }) => async (dispatch) => {
  // console.log("InvoiceControl",InvoiceControl)
  const response = await effect.postInvoiceControl(invoiceControl);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("invoicingControlAdded"), "success"));
  dispatch(requestInvoiceControl());
};

export const addProvisionControl = (invoiceProvision, callback = () => { }) => async (dispatch) => {
  // console.log("invoiceProvision",invoiceProvision)
  const response = await effect.postInvoiceProvision(invoiceProvision);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("invoicingProvisionAdded"), "success"));
  // dispatch(requestInvoiceControl());
};

export const addInvoiceSingleControl = (invoiceSingleControl, callback = () => { }) => async (dispatch) => {
  console.log("invoiceSingleControl",invoiceSingleControl)
  const response = await effect.postInvoiceSingleControl(invoiceSingleControl);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }

  callback();
  dispatch(notify(tr("invoicingSingleControlAdded"), "success"));
  dispatch(requestInvoiceSingleControl());
};

export const addInvoiceCampaigns = (invoiceCampaigns) => async (dispatch) => {
  console.log("invoiceCampaigns",invoiceCampaigns)
  const response = await effect.postInvoiceCampaigns(invoiceCampaigns);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(notify(tr("invoicingCampaigns"), "success"));
  // dispatch(requestInvoiceSingleControl());
};
export const addFeepGroups = (invoiceFeepGroups) => async (dispatch) => {
  console.log("invoiceFeepGroups",invoiceFeepGroups)
  const response = await effect.postInvoiceFeeGroups(invoiceFeepGroups);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  dispatch(notify(tr("invoicingFeepGroups"), "success"));
  // dispatch(requestInvoiceSingleControl());
};
export const addInvoiceReminder = (invoiceReminder) => async (dispatch) => {
  console.log("invoiceReminder",invoiceReminder)
  const response = await effect.postInvoiceReminder(invoiceReminder);
  if (response.error) {
    dispatch(notify(tr("dbConnectFailure"), "error"));
    return;
  }
  // dispatch(notify(tr("invoicingFeepGroups"), "success"));
  // dispatch(requestInvoiceSingleControl());
};

export const toggleAddEditProgramModal = (isOpen) => async (dispatch) => {
  dispatch(actionCreator.createAction(TOGGLE_ADD_EDIT_PROGRAM_MODAL,isOpen));
};


