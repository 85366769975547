import * as actionCreator from "@utils/actionCreator.js";

const SLICE = "notification";

export const NEW_NOTIFICATION = `${SLICE}/NEW_NOTIFICATION`;
export const notify = (message, type) => {
  const payload = { message, messageType: type };

  return actionCreator.createAction(NEW_NOTIFICATION, payload);
};

export const DISMISS_NOTIFICATION = `${SLICE}/DISMISS_NOTIFICATION`;
export const dismiss = (index) => {
  return actionCreator.createAction(DISMISS_NOTIFICATION, index);
};
