import React from "react";

// Ionicon 3 arrow icons' React class wrappers for easier usage.

const _arrow = (icon, className, style) => {
  return <span className={`${icon} ${className}`} style={style}></span>;
};

const ArrowUp = ({ style, className }) => {
  return _arrow("ion-ios-arrow-up", className, style);
};

const ArrowDown = ({ style, className }) => {
  return _arrow("ion-ios-arrow-down", className, style);
};

const ArrowLeft = ({ style, className }) => {
  return _arrow("ion-ios-arrow-left", className, style);
};

const ArrowRight = ({ style, className }) => {
  return _arrow("ion-ios-arrow-right", className, style);
};

export { ArrowUp, ArrowDown, ArrowLeft, ArrowRight };
