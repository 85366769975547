import { removeNullValues, resolveFieldValueFromObject } from "@utils/common.js";
import { Fetcher, fetchWithRetry } from "@utils/fetcher.js";
import apiUrl from "@utils/url.js";

// Paper Code effects
export async function fetchAllPaperCodes() {
  const url = apiUrl("papers");

  return fetchWithRetry("getJson", [url])
    .then((paperCodes = []) => {
      return paperCodes;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function fetchPaperCodeGroups() {
  const url = apiUrl("papergroup");

  return fetchWithRetry("getJson", [url])
    .then((paperCodeGroups = []) => {
      return paperCodeGroups;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function postPaperCode(paperCode) {
  const url = apiUrl(`papers/papers`);

  const payload = {
    papercode: paperCode.papercode,
    papername: paperCode.papername,
    winsaleurl: paperCode.winsaleurl,
    phone: paperCode.phone,
    isweightfactor: paperCode.isweightfactor,
    imageid: paperCode.imageid,
    url: paperCode.url,
    focuspaper: resolveFieldValueFromObject(paperCode.focuspaper),
    businessunitid: resolveFieldValueFromObject(paperCode.businessunitid),
    paperclass1: resolveFieldValueFromObject(paperCode.paperclass1),
    paperclass2: resolveFieldValueFromObject(paperCode.paperclass2),
    paperclass3: resolveFieldValueFromObject(paperCode.paperclass3),
    descriptivetextid: paperCode.descriptivetextid ? paperCode.descriptivetextid : 0,
    textid: paperCode.textid ? paperCode.textid : 0,
    papergroupid: resolveFieldValueFromObject(paperCode.papergroupid),
  };
  return Fetcher.post(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}


export async function postPaperCodeInGroup(paperCode) {
  const url = apiUrl(`papergroupitems`);
  let paperGroupId = resolveFieldValueFromObject(paperCode.papergroupid);
  const payload = {
    papercode: paperCode.papercode,
    papergroupid: paperGroupId,
  };
  return Fetcher.post(url, payload)
    .then(() => {
      return paperGroupId;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deletePaperCodeFromGroup(paperCode) {
  let paperGroupId = resolveFieldValueFromObject(paperCode.oldPaperGroupId);
  const url = apiUrl(`/papergroupitems/${paperGroupId}/${paperCode.papercode}`);
  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function putPaperCode(paperCode) {
  const url = apiUrl(`/papers/papers/${paperCode.papercode}`);

  let payload = {
    ...paperCode,
    papercode: paperCode.papercode,
    papername: paperCode.papername,
    focuspaper: resolveFieldValueFromObject(paperCode.focuspaper),
    businessunitid: resolveFieldValueFromObject(paperCode.businessunitid),
    paperclass1: resolveFieldValueFromObject(paperCode.paperclass1),
    paperclass2: resolveFieldValueFromObject(paperCode.paperclass2),
    paperclass3: resolveFieldValueFromObject(paperCode.paperclass3),
    textid: paperCode.textid ? paperCode.textid : "",
    papergroupid: resolveFieldValueFromObject(paperCode.papergroupid),
  };
  payload = removeNullValues(payload);
  return Fetcher.put(url, payload)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function deletePaperCode(paperCode) {
  const url = apiUrl(`/papers/papers/${paperCode.papercode}`);

  return Fetcher.delete(url)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}